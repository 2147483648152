<template>
  <div class="layout-page">
    <TopBarView />
      <div class="content-wrapper">
        <!-- <Search /> -->
        <!-- Content -->
        <div class="">
          <div>
            <div class="flex-grow-1 container-p-y px-4">
              <div class="d-flex justify-content-between aling-items-center">
                  <div>
                    <h4 class="fw-bold py-3 mb-4">
                        <span class="text-muted fw-light">Product</span> Settings
                    </h4>
                  </div>
                  <div>                
                    <router-link to="/product" class="btn btn-primary mt-2" >Back to List</router-link>
                    <!-- <button class="btn btn-primary mt-2" @click="toggleComponent" v-if="listShow"> + Add</button>
                    <button class="btn btn-primary mt-2" @click="toggleComponent" v-if="formShow">Back to List</button> -->
                  </div>
              </div>
                <div class="">
                <!-- Basic Layout & Basic with Icons -->
                <div v-if="successReport" class="alert alert-success alert-dismissible" role="alert">
                  Successfully
                  <button type="button" class="btn-close"  aria-label="Close"></button>
                </div>
                <div v-if="errorPrice" class="alert alert-danger alert-dismissible" role="alert">
                    Please Give The Valide Price Like (36.56) or (58.56) In The Buy & Sell Price 
                    <button type="button" class="btn-close" aria-label="Close"></button>
                </div>
                  <div class="d-flex justify-content-center align-items-center" v-if="loading">
                    <div class="spinner-border text-primary" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                  <form name="" @submit.prevent="handleSubmit" v-else>
                    <div class="row">
                      <!-- Basic Layout -->
                      <div class="col-md-9 col-sm-12">
                        <div class="card">
                          <div class="card-body">
                            <!-- <div class="d-flex justify-content-center align-items-center" v-if="fetchCategoryList.length <= 1">
                              <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </div> -->
                            <!-- <div class="card-body" v-else> -->
                            <div class="">
                              <!-- <div class="row mb-3">
                                <label
                                  class="col-sm-12 col-form-label"
                                  for="basic-icon-default-fullname"
                                  >Select Category</label
                                >                 
                                <div class="col-sm-12">
                                  <div class="input-group input-group-merge">
                                    <span
                                      id="basic-icon-default-fullname2"
                                      class="input-group-text"
                                      ><i class="bx bx-buildings"></i
                                    ></span>
                                    <select class="form-select" v-model="formData.category_id">
                                      <option value="">-- Select Category-- </option>
                                      <option v-for="category in fetchCategoryList" :key="category.id" :value="category.id">{{ category.name }}</option>
                                    </select>
                                    <input list="category" class="form-control drop-select-input" v-model="selectedCategoryName" @input="selectCategory">
                                      <datalist id="category">
                                        <option value="">-- Select Category--</option>
                                        <option v-for="category in fetchCategoryList" :key="category.id" :value="category.name">{{ category.name }}</option>
                                      </datalist>
                                  </div>
                                </div>
                              </div> -->
                              <!-- <div class="row mb-3">
                                <label
                                  class="col-sm-12 col-form-label"
                                  for="basic-icon-default-store"
                                  >Select Category 
                                </label>                                
                                    <SeLect2 v-model="formData.category_id" required :placeholder="'Select Category'" :options="fetchCategoryList" class="w-100 my-select" @change="myChangeEvent" @select="mySelectEvent"  :multiple="false"/>
                              </div> -->
                              <div class="row mb-3">
                                <label
                                  class="col-sm-12 col-form-label"
                                  for="basic-icon-default-store"
                                  >Name 
                                </label>
                                <div class="col-sm-12">
                                    <input type="text" class="form-control" id="basic-icon-default-fullname" placeholder="Name" v-model="formData.name" required>
                                </div>
                              </div>
                              <div class="row mb-3">
                                <label
                                  class="col-sm-12 col-form-label"
                                  for="basic-icon-default-store"
                                  >Description
                                </label>
                                <div class="col-sm-12">
                                  <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Description" v-model="formData.description"></textarea>
                                </div>
                              </div>      
                              <div class="row mb-3">
                                  <div class="col-md-4 col-sm-12 mb-3">
                                    <label
                                      class="col-sm-12 col-form-label"
                                      for="basic-icon-default-store"
                                      >Buy Price 
                                    </label>
                                    <div class="col-sm-12">
                                        <input type="text" class="form-control" id="basic-icon-default-fullname" placeholder="Buy Price" v-model="formData.buy_price" required>
                                    </div>
                                  </div>
                                  <div class="col-md-4 col-sm-12 mb-3">
                                    <label
                                      class="col-sm-12 col-form-label"
                                      for="basic-icon-default-store"
                                      >Wholesale Price 
                                    </label>
                                    <div class="col-sm-12">
                                        <input type="text" class="form-control" id="basic-icon-default-fullname" placeholder="Wholesale Price" v-model="formData.wholesale_price" required>
                                    </div>
                                  </div>
                                  <div class="col-md-4 col-sm-12 mb-3">
                                    <label
                                      class="col-sm-12 col-form-label"
                                      for="basic-icon-default-store"
                                      >Sale Price 
                                    </label>
                                    <div class="col-sm-12">
                                        <input type="text" class="form-control" id="basic-icon-default-fullname" placeholder="Sale Price" v-model="formData.sell_price" required>
                                    </div>
                                  </div>
                                  <div class="col-md-6 col-sm-12">
                                    <label
                                        class="col-sm-12 col-form-label"
                                        for="basic-icon-default-store"
                                        >Alert  Quantity
                                      </label>
                                      <div class="col-sm-12">
                                        <input type="text" class="form-control" id="basic-icon-default-fullname" placeholder="Sale Price" v-model="formData.alert_quantity" required>
                                      </div>
                                  </div>
                                  <div class="col-md-6 col-sm-12 mb-3">
                                    <label
                                        class="col-sm-12 col-form-label"
                                        for="basic-icon-default-store"
                                        >Unit
                                      </label>
                                      <div class="col-sm-12">
                                          <select class="form-select" v-model="formData.unit">
                                            <option value="">-- Select Unit--</option>
                                            <option value="PCS" >Pcs</option>
                                            <option value="KG">Kg</option>
                                            <option value="ML">Ml</option>
                                            <option value="GM">Gm</option>
                                            <option value="POUND">Pound </option>
                                            <option value="PACKET">Packet </option>
                                            <option value="MILIMETER">Mm</option>
                                            <option value="PAIR">Pair</option>
                                          </select>
                                      </div>
                                  </div>
                              </div>         
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-12">
                        <div class="" v-for="(dataItem, index) in fettchDataArray" :key="index">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="d-flex justify-content-center align-items-center" v-if="loading">
                                <div class="spinner-border text-primary" role="status">
                                  <span class="visually-hidden">Loading...</span>
                                </div>
                              </div>
                              <div v-else>
                                <label for="basic-icon-default-fullname">{{ dataItem.title }}</label>
                                <select class="form-select" v-model="selectedValues[index]" :key="dataItem.id" @change="updateSelectedChildId">
                                  <option :value="undefined"> -- Select --</option>
                                  <option v-for="dataItemchildren in dataItem.children" :key="dataItemchildren.id" :value="dataItemchildren.id">{{ dataItemchildren.title }}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 col-sm-12">
                        <div class="col-sm-12">
                            <button type="submit" class="btn btn-primary mt-3" :disabled="submitBtn">
                              <span v-if="!submitBtn">Save</span> 
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="submitBtn"></span>
                              <span class="visually-show" v-if="submitBtn"> Saving ....</span>
                            </button>
                          </div>         
                      </div>     
                    </div>
                  </form>
                </div>              
            </div>
          </div>
        </div>
      <Footer />
      <!-- / Footer -->

      <div class="content-backdrop fade"></div>
    </div>
    <!-- / Layout wrapper -->
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue';
// import CkEditor from "../../../components/Ckeditor.vue";
import { DOAMIN_PATH } from "@/api";
import { useStore } from 'vuex'; // Import useStore from Vuex
import axios from '../../../../node_modules/axios/index';
import Footer from "../../FooterView.vue";
import TopBarView from "../../TopBarView.vue";

interface mappingData {
  id: number,
  parent_id: number,
  title : string,
  description: string,
  status: number,
  created_at: string,
  updated_at: string,
  selectedChildId: number
  children: [
    {
      id: number,
      parent_id: number,
      title : string,
      description: string,
      status: number,
      created_at: string,
      updated_at: string
    }
  ]
  user: {
    id: number,
    type: string,
    firstname: string,
    lastname: string
  }
}
// Define a new global component called button-counter
export default defineComponent({
components: { Footer, TopBarView},
name: "product-form", // Rename the component to a multi-word name
// import Select2 from 'vue3-select2-component';
// Define a new global component called button-counter
 data() {
   const store = useStore();
   const token = store.state.auth.token || localStorage.getItem('token');
   const userId = store.state.auth.userId || localStorage.getItem('userId');
   const typeId = store.state.auth.typeId || localStorage.getItem('typeId');  
   const storeId = store.state.auth.storeId || localStorage.getItem('storeId');
   return {
       myValue: '',
       selectedCategory: null,
       successReport: false,
       typeId,
       storeId,
       itemId: '',
       store,
       token,
       userId, 
       errorPrice: false,
       loading: false,
       submitBtn: false,
       quantity: 0,
       selectedCategoryName: '',
       searchQuery: '',
       fetchCategoryList: [{
           id: Number,
           text: '',
       }],
       fettchDataArray: ref([] as unknown as mappingData[]), 
       selectedValues: [] as number[],// Temporary storage for each select
       formData: ref({
         id: Number,
         name: '', 
         description:'',
         thumb:'',
         buy_price: Number(0.00),
         sell_price: Number(0.00),
         wholesale_price: Number(0.00),
         alert_quantity: Number(10),
         unit:'',
         category_id: Number,
         status: Number,
         user_id: Number,
         store_id: Number,
         features: '',
         children: [
           {
             id: Number,
             parent_id: Number,
             title : '',
             description: '',
             status: Number,
             created_at: '',
             updated_at: ''
           }
         ],
       }),
       stockformData: {
         id: Number,
         type : 'IN',
         product_id: Number,
         user_id: Number,
         store_id: Number,
         quantity: Number(0),
       },

       selectedChildIdsString: '',
   }
 },
watch: {
 '$route.params.id': {
   immediate: true,
   handler(newVal) {
     if (newVal) {
       this.fetchData(newVal);
       this.itemId = newVal;
     }
   },
 },
},
created() {
    // this.fetchCategoryFunc(); // Call the method to fetch category data when the component is created
  },
methods: {    
async fetchAllFeatureData() {
    try {
      this.loading = true;
      // Update the axios request to include page and size parameters
      const response = await axios.get(
        DOAMIN_PATH + 'feature',
        {
          params: {
            // type_id: this.storeId
            // TypeId: this.typeId,
          },
          headers: {
            Authorization: `Bearer ${this.token}`,
            // StoreId: this.storeId,
            // TypeId: 9,
            // TypeId: this.typeId ? this.typeId : '',
          },
        }
      );

      if (response.status === 200) {
        this.fettchDataArray = response.data.data;
        this.loading = false;
      }
    } catch (error) {
      console.error(error);
      this.loading = false;
    }
  },
 async fetchData(id: number) {
   try {
    this.loading = true;
     if (!this.token) {
       throw new Error('Token not found');
     }
     const response = await (axios as any).get(DOAMIN_PATH + `product/${id}`, {
       headers: {
         Authorization: `Bearer ${this.token}`,
       },
     });
     if (response.status === 200) {
       this.formData = response.data.data;
       this.selectedValues = this.formData.features.split(",").map(Number);
       this.loading = false;
     }
   } catch (error) {
     console.error(error);
     this.loading = false;
   }
 },
 async updateSelectedChildId() {
      // Concatenate all selected values into a single string
      // this.formData.features = this.selectedValues
      console.log('this.selectedValues', this.selectedValues);
      this.formData.features = this.selectedValues
      .filter((value) => value)
      .map((value) => Number(value).toString())
      .join(',');
      //convert this.formData.features to string '' break
      this.formData.features = this.formData.features.toString();
      console.log('this.formData.features', this.formData.features);
},
 async handleSubmit() {
  this.formData.store_id = this.storeId;
  this.formData.user_id = this.userId;
  // const floatRegex = /^[+-]?\d+(\.\d+)?$/;
  const floatRegex = /^\d+(\.\d{1,2})?$/; 
   try {
     if (this.itemId) {
      this.loading = true;
      this.submitBtn = true;
      if (floatRegex.test(this.formData.buy_price.toString())) {
          //check the floatRegex for the floating-point buy number
          if(floatRegex.test(this.formData.sell_price.toString())){
            //check the floatRegex for the floating-point sell number
            const parsedFloatBuy = parseFloat(this.formData.buy_price.toString());
            const parsedFloatSale = parseFloat(this.formData.sell_price.toString());
          if (!isNaN(parsedFloatBuy )) {
            // Handle the floating-point number
            if(!isNaN( parsedFloatSale)){
              console.log('_this is if block', this.formData);
              const response = await (axios as any).put(
                DOAMIN_PATH + 'product/' + this.itemId,
                this.formData,
                {
                  headers: {
                    Authorization: `Bearer ${this.token}`,
                  }
                }
              );
              if(response.data.message == "Updated successfully!"){
                console.log();
                this.successReport = true;
                this.loading = false; 
                this.submitBtn = false;
                setTimeout(() => { this.successReport = false; }, 3000);
              }
            }
            else{
              this.errorPrice = true;
              this.loading = false;
              this.submitBtn = false;
              setTimeout(() => { this.errorPrice = false; }, 8000);
            }
            // Now you can send parsedFloat to the backend.
          } else {
            this.errorPrice = true;
            this.loading = false;
            this.submitBtn = false;
            setTimeout(() => { this.errorPrice = false; }, 8000);
          }
          }
          else{
            this.errorPrice = true;
            this.loading = false;
            this.submitBtn = false;
            setTimeout(() => { this.errorPrice = false; }, 8000);
          }
        } else {
          this.errorPrice = true;
          this.loading = false;
          this.submitBtn = false;
          setTimeout(() => { this.errorPrice = false; }, 8000);
        }
     } else {
        this.submitBtn = true;
        console.log('_this is else block');
       if (floatRegex.test(this.formData.buy_price.toString())) {
        //check the floatRegex for the floating-point buy number
        if(floatRegex.test(this.formData.sell_price.toString())){
          //check the floatRegex for the floating-point sell number
          const parsedFloatBuy = parseFloat(this.formData.buy_price.toString());
          const parsedFloatSale = parseFloat(this.formData.sell_price.toString());
        if (!isNaN(parsedFloatBuy )) {
          // Handle the floating-point number
          if(!isNaN( parsedFloatSale)){
            this.formData.store_id = this.storeId; 
            this.formData.user_id = this.userId; 
              const response = await (axios as any).post(DOAMIN_PATH + 'product', 
              this.formData,
                {
                  headers: {
                    Authorization: `Bearer ${this.token}`,
                  }
                }
              );
              console.log('_category_respons_', response.data);
              console.log('_form_data', this.formData);
              if (response.status == 201) {
                const productDataId = response.data.data.id;
                console.log('productDataId', productDataId);
                this.stockformData.product_id = productDataId;
                this.stockformData.store_id = this.storeId;
                this.stockformData.user_id = this.userId;
                this.stockformData.quantity = this.quantity;
                console.log('_stockformData', this.stockformData);
                const stockResponse = await (axios as any).post(DOAMIN_PATH + 'stock', 
                this.stockformData,
                  {
                    headers: {
                      Authorization: `Bearer ${this.token}`,
                    }
                  }
                );
                console.log('_stock_respons_', stockResponse.data);
                if (stockResponse.status == 201) {
                  this.successReport = true; 
                  this.loading = false;
                  this.submitBtn = false;
                  setTimeout(() => { this.successReport = false; }, 3000);
                }
                this.stockformData = {
                  id: Number,
                  type: 'IN',
                  product_id: Number,
                  store_id: Number,
                  user_id: Number,
                  quantity: 0,
                }
                this.formData = {
                  id: Number,
                  name: '', 
                  description:'',
                  thumb:'',
                  buy_price: Number(0.00),
                  sell_price: Number(0.00),
                  wholesale_price: Number(0.00),
                  alert_quantity: Number(10),
                  unit:'',
                  category_id: Number,
                  status: Number,
                  user_id: Number,
                  store_id: Number,
                  features: '',
                  children: [
                    {
                      id: Number,
                      parent_id: Number,
                      title : '',
                      description: '',
                      status: Number,
                      created_at: '',
                      updated_at: ''
                    }
                  ],
                };
                this.successReport = true;
                setTimeout(() => { this.successReport = false; }, 3000);
              }
          }
          else{
            this.errorPrice = true;
            this.submitBtn = false;
            setTimeout(() => { this.errorPrice = false; }, 8000);
          }
          // Now you can send parsedFloat to the backend.
        } else {
          this.errorPrice = true;
          this.submitBtn = false;
          setTimeout(() => { this.errorPrice = false; }, 8000);
        }
        }
        else{
          this.errorPrice = true;
          this.submitBtn = false;
          setTimeout(() => { this.errorPrice = false; }, 8000);
        }
       
       } else {
        this.errorPrice = true;
        this.submitBtn = false;
        setTimeout(() => { this.errorPrice = false; }, 8000);

      }
      
     }
   } catch (error) {
    this.submitBtn = false;
     console.error(error);
   }
  // this.formData = {...this.formData, ...this.formData2};
  // const selectedChildIdsString = this.formData.selectedChildId.join(',');
  // console.log('_formData', ...this.formData, selectedChildIdsString); 
 },
//  async selectCategory() {
//     const selectedCategory = this.fetchCategoryList.find(category => category.text === this.selectedCategoryName);
//     if (selectedCategory) {
//       this.formData.category_id = selectedCategory.id;
//     } else {
//       // If the selected category is not found, you may handle it accordingly
//       this.formData.category_id = Number; // Or any default value you prefer
//     }
//   },
  myChangeEvent(val: any){
      console.log(val);
  },
  mySelectEvent({id, text}: any) {
      console.log({id, text})
  },
  
},
mounted() {
 // Set isLoading to true when the component is mounted
//  this.fetchCategoryFunc();
this.fetchAllFeatureData();
},
})
</script>


<style>
.my-select .select2{
  padding: 0.2375rem 0.475rem;
  font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.53;
    color: #697a8d;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d9dee3;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
}
.select2-container--default .select2-selection--single{
  border: none !important;
}
</style>


