<template>
  <!-- toster area start  -->
  <!-- toster area start  -->
  <!-- <div class="toast-container z-3 position-fixed" style="z-index: 999; right: 20px; top: 90px">
    <div v-for="(item, index) in cartItems" :key="index">
      <div class="bs-toast toast fade show bg-warning" role="alert" aria-live="assertive" aria-atomic="false" v-if="item.toaster">
        <div class="toast-header">
          <i class="bx bx-bell me-2"></i>
          <div class="me-auto fw-medium">SL: {{index+1}} </div>
          <strong>{{ item.product }}</strong><span></span><br>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close" @click="hideToaster"></button>
        </div>
        <div class="toast-body text-wrap">
          Product Added Quantity: {{ item.quantity }}
        </div>
      </div>
    </div>
  </div> -->
  <div class="position-fixed d-block d-md-none" style="z-index: 999; right: 20px; top: 90px" v-if="totalQuantity > 0">
    <a class="nav-link dropdown-toggle hide-arrow" href="#addtocard">
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-cart4" viewBox="0 0 16 16">
        <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5M3.14 5l.5 2H5V5zM6 5v2h2V5zm3 0v2h2V5zm3 0v2h1.36l.5-2zm1.11 3H12v2h.61zM11 8H9v2h2zM8 8H6v2h2zM5 8H3.89l.5 2H5zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0m9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0"/>
      </svg>
      <span class="badge bg-danger rounded-pill badge-notifications">{{ totalQuantity }}</span>
    </a>
  </div>
  <!-- toster area end -->
  <!-- Sale Product -->
  <div class="row" >
    <div class="col-md-6">
      <!-- search form start  -->
      <div class="card mb-1">
        <div class="card-body">    
          <div class="d-flex justify-content-between">
              <!-- <div class="col-3">
                <div class="input-group">
                  <select class="form-select" aria-label="Default select example">
                    <option selected>-- Search By --</option>                    
                    <option value="0">Barcode </option>
                    <option value="1">Category</option>
                    <option value="2">Company </option>
                    <option value="3">Product </option>
                  </select>
                </div>
              </div> -->
              <div class="w-100">
                <div class="input-group pe-2">
                  <input type="text" class="form-control" placeholder="Enter Search Key!" v-model="searchKey">
                </div>
              </div>
              <div class="">
                <button type="button" class="btn btn-primary me-2 mt-sm-0 mt-2 " @click="searchProductData"><font-awesome-icon :icon="['fas', 'magnifying-glass']"/></button>
                <button type="button" class="btn btn-default border mt-sm-0 mt-2" @click="clearSearchKey"><font-awesome-icon :icon="['fas', 'xmark']"/></button>
              </div>              
          </div>
        </div>
      </div>
      <!-- search form end  -->
      <!-- table start  -->
      <div class="card">
        <div class="card-body table-responsive">
          <div class="d-flex justify-content-center align-items-center" v-if="loading">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
          <div v-else >
            <div class=" d-md-block d-none">
              <table class="table card-table" v-if ="fettchDataArray.length > 0">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Price</th>
                    <th>In Stock</th>
                    <th>#</th>
                  </tr>
                </thead>
                <tbody class="table-border-bottom-0" >
                  <tr v-for="(dataItem, index) in fettchDataArray" :key="dataItem.id">
                    <td>{{ index + 1 }}</td>
                    <!-- <td v-if="userData?.type=='SUPERADMIN'">{{ dataItem.store?.name }}</td> -->
                    <td><p class="mb-0">{{ dataItem.name }}</p>
                    </td>                
                    <td> P: {{ dataItem.buy_price }} | W: {{ dataItem.wholesale_price }} | S: {{ dataItem.sell_price }}</td>
                    <td>
                      <span v-if="dataItem.stocks[0]?.total_quantity">{{dataItem.stocks[0]?.total_quantity }} </span>
                      <span v-else>00 </span>
                    </td>
                    <td>
                      <span class="text-danger text-capitalize" v-if="dataItem.stocks[0]?.total_quantity == null">Add Stock</span>
                      <span class="text-danger text-capitalize" v-else-if="parseInt(dataItem.stocks[0]?.total_quantity) <= 0">
                        Stock Out
                      </span>
                      <!-- <span class="text-danger text-capitalize" v-if="dataItem.stocks && dataItem.stocks.length > 0 && parseInt(dataItem.stocks[0]?.total_quantity) <= 0">
                        Stock Out
                      </span> -->
                      <button type="button" class="me-2 btn btn-sm btn-primary" @click="addItemToCart(dataItem)" v-else>
                      <!-- <button type="button" class="me-2 btn btn-sm btn-primary" @click="addItemToCart(dataItem)" :disabled="parseInt(dataItem.stocks[0]?.total_quantity) <= 0"> -->
                      <!-- <button type="button" class="me-2 btn btn-sm btn-primary" @click="addItemToCart(dataItem)" :disabled="dataItem.in_stock == 0"> -->
                        <font-awesome-icon :icon="['fas', 'plus']" />
                      </button>
                      
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-else>
                <h5 class="m-0 text-center"> No Item Found</h5>
              </div>
            </div>
            <div class="d-md-none d-block">
              <div class="mb-4" v-for="dataItem  in fettchDataArray" :key="dataItem.id">
                <div class="">
                <strong class="mb-0">Name: {{ dataItem.name }}</strong>
                <small> ({{ dataItem.category?.name }}) </small>
                <p class="mb-0 fw-thin"><strong>Sell </strong>/ Buy <strong>Price:</strong> P: {{ dataItem.buy_price }} | W: {{ dataItem.wholesale_price }} | S: {{ dataItem.sell_price }}</p>
                <p class="">
                  <span v-if="dataItem.stocks[0]?.total_quantity">Qantity: {{dataItem.stocks[0]?.total_quantity }} </span>
                  <span v-else>00</span>
                </p>
                <span class="text-danger text-capitalize" v-if="parseInt(dataItem.stocks[0]?.total_quantity) <= 0">Out of Stock</span>
                <button type="button" class="me-2 btn btn-sm btn-primary" @click="addItemToCart(dataItem)" v-else>
                  <!-- <button type="button" class="me-2 btn btn-sm btn-primary" @click="addItemToCart(dataItem)" :disabled="dataItem.in_stock == 0"> -->
                    <font-awesome-icon :icon="['fas', 'plus']" />
                  </button>
                </div>
                <hr>
              </div>
              
            </div>
          </div>          
        </div> 
      </div>
      <!-- // table end -->
    <!-- modal end  -->
    </div>
    <!-- sale board  -->
    <div class="col-md-6">
      <form @submit.prevent="addOrderFunc">
        <!-- customer info  -->
        <div class="card mb-1 mt-sm-0 mt-3" id="addtocard">
          <div class="card-body">    
            <!-- here  -->
            <div>
              <div class="nav nav-pills nav-fill flex-nowrap" id="pillNav2" role="tablist">
                <button class="nav-link active rounded-5" id="walk-tab" @click="saleForm.customer_type = 'WHOLESALE'" data-bs-toggle="pill" data-bs-target="#walk-in-customer" type="button" role="tab" aria-selected="true">
                  <input class="form-check-input d-none" type="radio" name="customer_type" id="whole_sale" v-model="saleForm.customer_type" value="WHOLESALE" checked>
                  <label class="form-check-label w-100" for="whole_sale" >
                    Wholesale
                  </label>
                </button>
                <button class="nav-link rounded-5" id="registered-tab" @click="saleForm.customer_type = 'REGULAR'" data-bs-toggle="pill" data-bs-target="#registered-tab-pane" type="button" role="tab" aria-selected="false" >
                  <input class="form-check-input d-none" type="radio" name="customer_type" id="regular" v-model="saleForm.customer_type" value="REGULAR">
                  <label class="form-check-label w-100" for="regular" >
                    Regular
                  </label>
                </button>
              </div>
              <div class="tab-content py-4 px-0" id="myTabContent">
                <div class="tab-pane fade show active" id="walk-in-customer" role="tabpanel" aria-labelledby="walk-tab">
                  <div v-if="registrationMessage">
                    <p class="text-danger text-wrap"> Please Enter the Registered Customer To Manage the Due Amount</p>
                  </div>
                  <div class="d-flex flex-sm-nowrap flex-wrap justify-content-between">
                    <div class="mb-3 me-3 w-100">
                      <input type="text" class="form-control" placeholder="Store Phone" v-model="saleForm.customer.phone" @input="searchCustomer" :required="saleForm.customer_type == 'WHOLESALE'">
                      <!-- Display suggestions -->
                      <ul v-if="suggestions.length" class="form-control suggestions-list">
                        <li v-for="customer in suggestions" :key="customer.id" @click="selectCustomer(customer)" class="p-2">
                          {{ customer.phone }}
                        </li>
                      </ul >
                    </div>
                    <div class="mb-3 me-3 w-100">
                      <input type="text" class="form-control " id="name" placeholder="Store Name" v-model="saleForm.customer.name" @input="searchCustomer">
                    </div>
                  </div>
                  <div class="d-flex flex-sm-nowrap flex-wrap justify-content-between">
                    <div class="mb-3 me-3 w-100">
                      <textarea type="text" class="form-control " id="name" placeholder="Store Address" v-model="saleForm.customer.address" rows="3"></textarea>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="registered-tab-pane" role="tabpanel" aria-labelledby="registered-tab">
                  <div v-if="registrationMessage">
                    <p class="text-danger text-wrap"> Please Enter the Registered Customer To Manage the Due Amount</p>
                  </div>
                  <div class="d-flex flex-sm-nowrap flex-wrap justify-content-between">
                    <div class="mb-3 me-3 w-100">
                      <input type="text" class="form-control" placeholder="Customer Phone" v-model="saleForm.customer.phone" @input="searchCustomer" :required="saleForm.customer_type == 'WHOLESALE'">
                      <!-- Display suggestions -->
                      <ul v-if="suggestions.length" class="form-control suggestions-list">
                        <li v-for="customer in suggestions" :key="customer.id" @click="selectCustomer(customer)" class="p-2">
                          {{ customer.phone }}
                          {{ customer.firstname }}
                          <small>{{ customer.firstname }} {{ customer.lastname }}</small>
                        </li>
                      </ul >
                    </div>
                    <div class="mb-3 me-3 w-100">
                      <input type="text" class="form-control " id="name" placeholder="Customer Name" v-model="saleForm.customer.name" @input="searchCustomer">
                    </div>
                  </div>
                  <div class="d-flex flex-sm-nowrap flex-wrap justify-content-between">
                    <div class="mb-3 me-3 w-100">
                      <textarea type="text" class="form-control " id="name" placeholder="Customer Address" v-model="saleForm.customer.address" rows="3"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end here  -->
          </div>
        </div>
        <!-- end customer info  -->
        <!-- order item  -->
        <div class="card" >
          <div class="">
          <div class="d-md-flex d-none">
            <div class="card-body table-responsive ">
              <table class="table card-table" v-if="cartItems.length > 0">
                <thead>
                  <tr>
                    <th>Sl.</th>
                    <!-- <th v-if="userData?.type=='SUPERADMIN'">Company</th> -->
                    <th>Product</th>
                    <th>Price x Qty</th>
                    <th>Total</th>
                    <th>-</th>
                  </tr>
                </thead>
                <tbody class="table-border-bottom-0">
                  <tr v-for="(item, index,) in cartItems" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.product }}</td>
                    <td>
                      <div class="input-group">
                        <span class="form-control-sm mx-0" v-if="saleForm.customer_type == 'WHOLESALE'" >{{ item.wholesale_price }}</span>
                        <span class="form-control-sm mx-0" v-else> {{ item.price }}</span>
                        <span class="form-control-sm mx-0">X</span>
                        <input type="number" class="form-control form-control-sm w-50" min="1" v-model="item.quantity" @input="calculateItemPriceByQuantity(index)">
                      </div>
                    </td>
                    <td>{{ parseFloat(item.total.toString()).toFixed(2) }} </td>
                    <td>
                      <button type="button" class="btn btn-sm btn-danger" @click="removeFromCart(index)">
                        X
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-else class="text-center fw-bold">
                <h6 class="m-0">Please Add Some Product</h6>
              </div>
            </div>
          </div> 
            <div class="mobile-view d-block d-md-none">
              <div class="card-body" v-if="cartItems.length > 0">
                <div class="" v-for="(item, index,) in cartItems" :key="index">
                  <div class="">                  
                  <strong>Name: {{ item.product }}</strong>
                    <div class="input-group">
                      <span class="" v-if="saleForm.customer_type == 'WHOLESALE'">Price: {{ item.wholesale_price}} Buy Price: {{ item.buy_price }}</span> &nbsp; <strong> X </strong>&nbsp;
                      <span class="" v-if="saleForm.customer_type == 'REGULAR'"> Price: {{ item.price }} Buy Price: {{ item.buy_price }}</span>
                      <span class=""> Quantity:</span>
                      <input type="number" class="form-control form-control-sm ms-2" min="1" v-model="item.quantity" @input="calculateItemPriceByQuantity(index)">
                    </div>
                    <p v-if="saleForm.customer_type == 'WHOLESALE'">Total: {{ parseFloat(item.wholesale_total.toString()).toFixed(2) }} </p> 
                    <p v-else>Total: {{ parseFloat(item.total.toString()).toFixed(2) }}</p> 
                    <button type="button" class="btn btn-sm btn-danger" @click="removeFromCart(index)">
                      <font-awesome-icon :icon="['fas', 'circle-xmark']" />
                    </button>
                  </div>
                  <hr>
                </div>
              </div>
              <div v-else class="card-body" > 
                <h6 class="text-center fw-bold m-0 p-0">Please Add Some Product</h6>
              </div>
            </div>
          </div> 
        </div>
        <!-- end order item  -->
        <!-- order info  -->
        <div class="card mt-1">
          <div class="card-body table-responsive my-0 d-md-flex d-none">
            <table class="table card-table">
              <tbody class="table-border-bottom-0 text-end">
                <tr>
                  <th class="col-8">Total</th>
                  <td>
                    <input type="number" class="form-control form-control-sm" v-model="saleForm.total" min="0" readonly>
                  </td>
                </tr>
                <tr>
                  <th>Discount (%)</th>
                  <td>
                    <input type="number" class="form-control form-control-sm" v-model="saleForm.discount" min="0" @input="calculateSubTotal()">
                  </td>
                </tr>
                <tr>
                  <th>Sub Total</th>
                  <td>
                    <input type="number" class="form-control form-control-sm" v-model ="saleForm.sub_total" min="0" readonly>
                  </td>
                </tr>
                <tr>
                  <th>Payment</th>
                  <td>
                    <div class="form-group">
                      <div class="input-group input-group-sm w-100">
                        <input type="text" class="form-control form-control-sm w-50" @input="calculateChangeAmount()" placeholder="Enter Payment" v-model="saleForm.payment" min="0">
                        <!-- <span class="input-group-text text-primary" id="basic-addon2">({{change_amount}})</span>  -->
                        <input type="text" class="form-control form-control-sm" v-model="change_amount" readonly>
                      </div>
                      <div v-if="inputValidity">
                        <small class="text-danger">Please Enter Valid Amount</small>
                      </div> 
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div> 
          <div class="card-body table-responsive my-0 d-md-none d-block">
            <p>Total: <input type="number" class="form-control form-control-sm" v-model="saleForm.total" min="0" readonly> </p>
            <p>Discount (%) <input type="number" class="form-control form-control-sm" v-model="saleForm.discount" min="0" @input="calculateSubTotal()"> </p>
            <p>Sub Total: <input type="number" class="form-control form-control-sm" v-model ="saleForm.sub_total" min="0" readonly> </p>
            <p>Payment: <input type="text" class="form-control form-control-sm" @input="calculateChangeAmount()" placeholder="Enter Payment" v-model="saleForm.payment" min="0"> </p>
            <!-- <span class="text-primary" id="basic-addon2">({{change_amount}})</span> -->
            <p>Due: <input type="text" class="form-control form-control-sm" v-model="change_amount" readonly></p>
            <div v-if="inputValidity">
              <small class="text-danger">Please Enter Valid Amount</small>
            </div> 
          </div> 
        </div>
        <!-- end order info  -->
        <div class="card mt-1">
          <div class="card-body">
            <div class="row">
            <div class="col-md-4 col-sm-12">
              <!-- <a href="" class="btn btn-default border">Draft (5)</a> -->
            </div>
            <div class="col-md-8 col-sm-12 text-end">
              <a href="" class="btn btn-default border m-1"><font-awesome-icon :icon="['fas', 'broom']" /></a>
              <!-- <button type="submit" class="btn btn-primary m-1" :disabled="submitBtn || cartItems.length <= 0"> -->
              <button type="submit" class="btn btn-primary m-1" :disabled="submitBtn || cartItems.length <= 0">
                <span v-if="!submitBtn">Confirm</span> 
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="submitBtn"></span>
                <span class="visually-show" v-if="submitBtn"> Selling...</span>
              </button>
            </div>
          </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script lang="ts">
import { DOAMIN_PATH } from "@/api";
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex'; // Import useStore from Vuex
import axios from '../../../../node_modules/axios/index';

interface mappingData {
  id: number,
  name: string,
  description : string,
  thumb: string,
  user_id: string,
  store_id: string,
  buy_price: number,
  wholesale_price: string,
  sell_price: number,
  unit: string,
  calculateSubtotal: number,
  stocks:[{
      product_id: 0,
      total_quantity: string
  }],    
  category: {
      id: number,
      name: string,
  },
  // total_quantity: string,
  in_stock: number,     
  store: {
    id: number,
    name: string
  },
  user: {
    id: number,
    type: string,
    firstname: string,
    lastname: string
  }
}

interface CartItem {
  product: string; // Adjust the type according to your actual data structure
  price: number;
  quantity: number;
  sale_total: number;
  wholesale_price: number;
  wholesale_total: number;
  buy_total: number;
  total: number;
  id: number;
  buy_price: number;
  profit: number;
  // toaster: boolean; // Add the toaster property
}

interface OrderItem {
  order_id: number,
  sell_price: number,
  buy_price: number,
  quantity: number,
  discount: number,
  total: number,
  profit: number,
  product_id: number,
  product_name: string
}
interface SuggesItem {
  id: number;
  name: string;
  firstname: string;
  lastname: string;
  address: string;
  phone: string;
}
export default defineComponent({
  name: "SaleBoard", // Rename the component to a multi-word name
  data() {
    const store = useStore();
    const token = store.state.auth.token || localStorage.getItem('token');
    const userId = store.state.auth.userId || localStorage.getItem('userId'); // convert user id to number
    
    const storeId = store.state.auth.storeId || localStorage.getItem('storeId');
    const typeId =  store.state.auth.typeId || localStorage.getItem('typeId');
    let userData = store.state.auth.userData || localStorage.getItem('userData');
    userData = JSON.parse(userData);
    return {      
      store,
      token,
      userId,
      userData: userData,
      Subtotal: 0,
      storeId,
      typeId,
      loading: false,
      fettchDataArray: ref([] as unknown as mappingData[]), // Explicitly define the type as Post[] // Explicitly define the type as Post[]
      cartItems: [] as CartItem[], 
      discountInput: '0', // Bind this to the discount input
      grandTotal: 0, // Store the calculated grand total
      grandWholesaleTotal: 0,
      change_amount: 0,
      orderItem: [] as OrderItem[],
      Profit: 0,
      saleForm: {
        customer_type: 'WHOLESALE',
        total: 0.00,
        wholesale_total: 0.00,
        whole_total: 0.00,
        discount: 0.00,
        sub_total: 0.00,
        due: 0.00,
        // profit: 0.00,
        vat: 0.00,
        tax: 0.00,
        // customer_id: 0, 
        // user_id: parseInt(this.userId), // convert to number
        user_id: 1,
        customer_id: null,
        customer:{
            id: Number,
            name: '',
            nid_no: '',
            phone: '',
            type: '',
            address: '',
            user_id: this.userId,
          },
        // order_items: [
        //   {
        //     product_id: 0,
        //     // product_name: '',
        //     customer_id: 0,
        //     quantity: 0,
        //     buy_price: 0,
        //     sell_price: 0,
        //     total: 0,
        //     profit: 0,
        //     user_id: 0,
        //   }
        // ],
        payment: 0.00,
        // payment api column name
      },
      paymentForm: {
        payment_type: 'SELL',
        payment_method: 'Cash', 
        customer_type: 'WHOLESALE', 
        bill_total: 0.00, 
        payment: 0.00, 
        due: 0,
        order_id: 0,
        customer_id: null,
        user_id: 0,
        status: "0",
      },
      order: {
        customer_type: "",
        discount: 0,
        id: 0,
        invoice_no: "",
        payment: 0,
        sub_total: 0,
        total: 0,
      },
      orderStatus: false,
      stockformData: {
         id: Number,
         type : 'OUT',
         product_id: Number,
         user_id: Number,
         store_id: Number,
         quantity: Number(0),
       },
       submitBtn: false,
       suggestions: [] as SuggesItem[], // Array to store suggested customers
       searchKey: '',
       inputValidity: false,
       registrationMessage: false,
      //  toaster: false,
      //totalQuantity: 0, // or any initial value
    };
  },

  watch: {
    cartItems: {
      deep: true,
      handler() {
        this.calculateSubTotal();
        this.calculateTotalPriceByItem();
      },
    },
    discountInput() {
      this.calculateSubTotal();
      this.calculateTotalPriceByItem();
    },
    'saleForm.customer_type'(newValue) {
      // Call addItemToCart without arguments
      console.log('watcher',newValue);
    },
  },
  computed: {
    totalQuantity(): any {
      return this.cartItems.reduce((total, item) => total + item.quantity, 0);
    },
  },
  methods: {
    // get all product data
    async fetchAllProductData() {
      try {
        this.loading = true;
        if (!this.token) {
            throw new Error('Token not found');
          }
          const response = await axios.get(DOAMIN_PATH + 'product',
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
              // storeId: this.storeId,
              // search_key: this.searchKey,
            }
          }
          );
        if (response.status === 200) {
          this.fettchDataArray = response.data.data.map((product: { sell_price: string; buy_price: string; wholesale_price: string; }) => ({
          ...product,
          sell_price: parseFloat(product.sell_price).toFixed(2),
          wholesale_price: parseFloat(product.wholesale_price).toFixed(2),
          buy_price: parseFloat(product.buy_price).toFixed(2),
        }));
          this.loading = false;
          // console.log('_prodct_array', this.fettchDataArray);
        }
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    async searchProductData() {
      try {
        this.fettchDataArray = [];
        this.loading = true;
        if (!this.token) {
            throw new Error('Token not found');
          }
          const response = await axios.get(DOAMIN_PATH + 'product',
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
              storeId: this.storeId,
            },
            params: {
              search_key: this.searchKey,
            }
          }
          );
        if (response.status === 200) {
          this.fettchDataArray = response.data.data.map((product: { sell_price: string; buy_price: string; wholesale_price: string; }) => ({
          ...product,
          sell_price: parseFloat(product.sell_price).toFixed(2),
          wholesale_price: parseFloat(product.wholesale_price).toFixed(2),
          buy_price: parseFloat(product.buy_price).toFixed(2),
        }));
          this.loading = false;
          // console.log('_prodct_array', this.fettchDataArray);
        }
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    async clearSearchKey(){
      this.searchKey = '';
      await this.fetchAllProductData();
    },

    async addOrderFunc() {
        console.log('__saleForm_', this.saleForm);
        const floatRegex = /^\d+(\.\d{1,2})?$/;
        try {
          if (!this.token) {
            throw new Error('Token not found');
          }
          this.submitBtn = true;
          this.saleForm.user_id = this.userId;
          this.saleForm.customer.user_id = this.userId;
          if(this.change_amount < 0) {
            this.saleForm.due = this.change_amount;
          }
          else{
            this.saleForm.due = 0;
          }
          if (floatRegex.test(this.saleForm.payment.toString())) {
            //check the floatRegex for the floating-point number
            //const parsedFloat = parseFloat(this.saleForm.payment.toString());
            this.inputValidity = false;  
            const registeredTab = document.getElementById('registered-tab'); 
            if (this.change_amount < 0 && this.registrationMessage == false && registeredTab && registeredTab.getAttribute('aria-selected') === 'false' ) {                
                registeredTab?.click();
                this.submitBtn = false; 
                this.registrationMessage = true;
                this.saleForm.customer_type = 'REGULAR';
            }
           else{              
               this.registrationMessage = false;
               console.log('_saleForm', this.saleForm,)
                if (this.saleForm.customer_type == 'REGULAR' && (typeof this.saleForm.customer_id === 'undefined' || this.saleForm.customer_id === null)) {
                  try {
                    console.log('Regular Customer', this.saleForm);
                    // Create the customer
                    const customerResponse = await axios.post(DOAMIN_PATH + 'customer', this.saleForm.customer, {
                      headers: {
                        Authorization: `Bearer ${this.token}`,
                      }
                    });
                    if (customerResponse.status === 201) {
                      // Set the customer_id after successfully creating the customer
                      this.saleForm.customer_id = customerResponse.data.data.id;
                      this.saleForm.user_id = this.userId;
                      // Proceed to create the order
                      const orderdataresponse = await axios.post(DOAMIN_PATH + 'order', this.saleForm, {
                        headers: {
                          Authorization: `Bearer ${this.token}`,
                          storeId: this.storeId,
                        }
                      });
                      if (orderdataresponse.status === 201) {
                        console.log('_order_orderdataresponse_', orderdataresponse.data.data);
                        const orderId = orderdataresponse.data.data.id;
                        await Promise.all(this.orderItem.map(async (item: any) => {
                        item.order_id = orderId;
                        const orderResponse = await (axios as any).post(DOAMIN_PATH + 'order-item', { order_id: item.order_id, ...item }, {
                        headers: {
                          Authorization: `Bearer ${this.token}`,
                        }
                        });
                          if (orderResponse.status == 201) {
                            console.log('_order_item_response_', orderResponse.data.data);
                            this.stockformData.id = item.id;
                            this.stockformData.product_id = item.product_id;
                            this.stockformData.user_id = this.userId;
                            this.stockformData.store_id = this.storeId;
                            this.stockformData.type = "OUT";
                            this.stockformData.quantity = -item.quantity;
                            const stockResponse = await (axios as any).post(DOAMIN_PATH + 'stock',
                              this.stockformData,
                              {
                                headers: {
                                  Authorization: `Bearer ${this.token}`,
                                }
                              }
                            );
                            if (stockResponse.status == 201) {
                              console.log('_stock_response_', stockResponse.data.data);
                            }
                          }
                      }));
                        this.loading = false;
                        this.submitBtn = false;
                        const id = orderdataresponse.data.data.id;
                        this.$router.push({ path: `invoice/${id}` });
                      }
                    }
                  } catch (error) {
                    console.error(error);
                  }
                }
                else {
                    console.log('Wholesale Customer', this.saleForm);
                    this.saleForm.user_id = this.userId;
                    const response = await axios.post(DOAMIN_PATH + 'order', this.saleForm, {
                    headers: {
                      Authorization: `Bearer ${this.token}`,
                    }
                    });
                    if (response.status === 201) {
                      console.log('_order_response_', response.data.data);
                      const orderId = response.data.data.id;
                      await Promise.all(this.orderItem.map(async (item: any) => {
                        item.order_id = orderId;
                        const orderResponse = await (axios as any).post(DOAMIN_PATH + 'order-item', { order_id: item.order_id, ...item }, {
                        headers: {
                            Authorization: `Bearer ${this.token}`,
                          }
                        });
                          if (orderResponse.status == 201) {
                            console.log('_order_item_response_', orderResponse.data.data);
                            this.stockformData.id = item.id;
                            this.stockformData.product_id = item.product_id;
                            this.stockformData.user_id = this.userId;
                            this.stockformData.store_id = this.storeId;
                            this.stockformData.type = "OUT";
                            this.stockformData.quantity = -item.quantity;
                            const stockResponse = await (axios as any).post(DOAMIN_PATH + 'stock',
                              this.stockformData,
                              {
                                headers: {
                                  Authorization: `Bearer ${this.token}`,
                                }
                              }
                            );
                            // console.log('_stock_respons_', this.stockformData);
                            if (stockResponse.status == 201) {
                              console.log('_stock_response_', response.data.data);
                            }
                          }
                      }))
                      this.paymentForm.bill_total = this.saleForm.sub_total;
                      this.paymentForm.payment = this.saleForm.payment;
                      this.paymentForm.due = this.change_amount;
                      this.paymentForm.customer_type = this.saleForm.customer_type;
                      this.paymentForm.customer_id = this.saleForm.customer_id || null;
                      this.paymentForm.order_id = orderId;
                      this.paymentForm.user_id = this.userId;
                      console.log('_paymentForm_', this.paymentForm);
                      const paymentRespose = await (axios as any).post(DOAMIN_PATH + 'payment',
                        this.paymentForm,
                        {
                          headers: {
                            Authorization: `Bearer ${this.token}`,
                            UserId: this.userId,
                          }
                        }
                      );
                      console.log('_paymentResponse_', paymentRespose);
                      // this.successReport = true;
                      this.loading = false;
                      this.submitBtn = false;
                      // setTimeout(() => {
                      //   this.successReport = false;
                      // }, 3000);
                      const id = response.data.data.id;
                        // this.$router.push({params: { id } });
                        //  this.fetchAllProductData();
                      // this.$router.push({ path: `invoice/${id}` });
                      this.$router.push({ path: `invoice/${id}` });
                    }
                }
           }
          }
          else{
            this.inputValidity = true;
            this.loading = false;
          }
          
        } catch (error) {
          console.error(error);
        }
      },
    async searchCustomer() {
      try {        
        if(this.saleForm.customer_type === 'WHOLESALE'){
          if (!this.token || !this.saleForm.customer.phone && !this.saleForm.customer.name) {
            this.suggestions = [];
            return;
          }
          const response = await axios.get(DOAMIN_PATH + 'store', {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
            params: {
              search_key: this.saleForm.customer.phone || this.saleForm.customer.name,
            }
          });

          if (response.status === 200) {
            this.suggestions = response.data.data;
          }
        }
        else{
          if (!this.token || !this.saleForm.customer.phone && !this.saleForm.customer.name) {
            this.suggestions = [];
            return;
          }
          const response = await axios.get(DOAMIN_PATH + 'customer', {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
            params: {
              search_key: this.saleForm.customer.phone || this.saleForm.customer.name,
            }
          });

          if (response.status === 200) {
            this.suggestions = response.data.data;
          }
        }
        
      } catch (error) {
        console.error(error);
      }
    },
    selectCustomer(customer: any) {
      this.saleForm.customer_id = customer.id;
      this.saleForm.customer.phone = customer.phone;
      this.saleForm.customer.name = customer.name;
      this.saleForm.customer.address = customer.address; 
      this.saleForm.customer.name = customer.firstname + ' ' + customer.lastname; 
      this.suggestions = []; // Clear suggestions
    },
    
    // add product to cart
    async addItemToCart(dataItem: { name: any; sell_price: any; id: any; wholesale_price: any; buy_price: any; }) {
      const existingItemIndex = this.cartItems.findIndex((item) => item.product === dataItem.name);
      if (existingItemIndex !== -1) {
        // Update item if it already exists in the cart
        const price = this.saleForm.customer_type === 'WHOLESALE'
          ? dataItem.wholesale_price
          : dataItem.sell_price;

        this.cartItems[existingItemIndex].quantity += 1;
        this.cartItems[existingItemIndex].sale_total = parseFloat(
          (this.cartItems[existingItemIndex].price * this.cartItems[existingItemIndex].quantity).toFixed(2)
        );
        this.cartItems[existingItemIndex].wholesale_total = parseFloat(
          (this.cartItems[existingItemIndex].wholesale_price * this.cartItems[existingItemIndex].quantity).toFixed(2)
        );
        this.cartItems[existingItemIndex].buy_total = parseFloat(
          (this.cartItems[existingItemIndex].buy_price * this.cartItems[existingItemIndex].quantity).toFixed(2)
        );
        this.cartItems[existingItemIndex].total = parseFloat(
          (price * this.cartItems[existingItemIndex].quantity).toFixed(2)
        );
        this.cartItems[existingItemIndex].profit = parseFloat(
          (this.cartItems[existingItemIndex].total - this.cartItems[existingItemIndex].buy_total).toFixed(2)
        );
      } else {
        // Add new item if it doesn't exist in the cart
        const price = this.saleForm.customer_type === 'WHOLESALE'
          ? dataItem.wholesale_price
          : dataItem.sell_price;

        const newItem = {
          id: dataItem.id,
          product: dataItem.name,
          price: dataItem.sell_price,
          wholesale_price: dataItem.wholesale_price,
          buy_price: dataItem.buy_price,
          quantity: 1,
          sale_total: dataItem.sell_price,
          wholesale_total: dataItem.wholesale_price,
          buy_total: dataItem.buy_price,
          total: price,
          profit: price - dataItem.buy_price,
        };
        this.cartItems.push(newItem);
      }

      // Update order_items
      this.orderItem = this.cartItems.map((item) => ({
          product_id: item.id,
          product_name: item.product,
          quantity: item.quantity,
          sell_price: item.price,
          buy_price: item.buy_price,
          total: item.total,
          profit: item.profit,
          discount: 0,
          order_id: 0,
          // user_id: 1,
          // customer_id: this.saleForm.customer_id !== null ? this.saleForm.customer_id : 1,
        }));

      // Recalculate totals
      this.calculateSubTotal();
      this.calculateTotalPriceByItem();
    },


    // async hideToaster(item: any) {
    //   item.toaster = false;
    // },
      /*remove item from cart list*/
    async removeFromCart(index: number) {
      this.cartItems.splice(index, 1);
      this.calculateSubTotal(); // Add a method to update totals if needed
    },

    // calculate total = price x quantity 
    // Calculate total price by item
    async calculateTotalPriceByItem() {
      let cartTotal = 0;
      let cartProfitTotal = 0;

      // Calculate totals based on customer type
      if (this.saleForm.customer_type === 'WHOLESALE') {
        cartTotal = parseFloat(
          this.cartItems.reduce(
            (total, item) => total + (item.quantity * item.wholesale_price), 
            0
          ).toFixed(2)
        );
      } else {
        cartTotal = parseFloat(
          this.cartItems.reduce(
            (total, item) => total + (item.quantity * item.price), 
            0
          ).toFixed(2)
        );
      }

      cartProfitTotal = parseFloat(
        this.cartItems.reduce(
          (profit, item) => profit + (item.quantity * item.buy_price), 
          0
        ).toFixed(2)
      );

      this.saleForm.total = cartTotal;
      this.saleForm.payment = cartTotal;
      this.Profit = cartTotal - cartProfitTotal;
    },

    // Calculate subtotal and apply discounts
    async calculateSubTotal() {
      let subtotal = 0;
      // Calculate subtotal based on customer type
      if (this.saleForm.customer_type === 'WHOLESALE') {
        subtotal = this.cartItems.reduce((sum, item) => {
          return sum + item.wholesale_price * item.quantity;
        }, 0);
      } else {
        subtotal = this.cartItems.reduce((sum, item) => {
          return sum + item.price * item.quantity; // Regular price
        }, 0);
      }

      subtotal = parseFloat(subtotal.toFixed(2));
      const discountPercentage = typeof this.saleForm.discount === 'number' 
        ? this.saleForm.discount 
        : parseFloat(this.saleForm.discount || '0');

      const discount = (discountPercentage / 100) * subtotal;
      const grandTotal = subtotal - discount;

      this.saleForm.sub_total = parseFloat(grandTotal.toFixed(2));
      this.saleForm.payment = parseFloat(grandTotal.toFixed(2));
      this.change_amount = parseFloat((this.saleForm.payment - this.saleForm.sub_total).toFixed(2));

      // Optional: update a computed property for live UI updates
      if (this.saleForm.payment < this.saleForm.sub_total) {
        this.inputValidity = true; // Show invalid input message
      } else {
        this.inputValidity = false;
      }
    },

    // Calculate price for specific item based on quantity
    async calculateItemPriceByQuantity(index: number) {
      const item = this.cartItems[index];
      const price = this.saleForm.customer_type === 'WHOLESALE' 
        ? item.wholesale_price 
        : item.price;

      item.buy_total = item.buy_price * item.quantity;
      item.total = price * item.quantity;
      item.profit = item.total - item.buy_total;

      // Update the saleForm order_items
      const saleFormItem = this.orderItem.find(
        (saleItem) => saleItem.product_id === item.id
      );
      if (saleFormItem) {
        saleFormItem.quantity = item.quantity;
      }

      this.calculateSubTotal();
    },


    async calculateChangeAmount(){
      // const changeAmount = this.grandTotal - this.saleForm.total;
      this.change_amount = Number((this.saleForm.payment - this.saleForm.sub_total).toFixed(2));
    }
  // Other methods
  },
  
  mounted() {
    this.fetchAllProductData();
  },
});
</script>
<style>
  .suggestions-list li{
    list-style: none;
    cursor: pointer;
  }
  .suggestions-list li:hover{
    background-color: #f7f7f7;
    border-radius: 5px;
  }
</style>