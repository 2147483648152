<template>
  <div class="">
   <!-- Basic Layout & Basic with Icons -->
   <div v-if="successReport" class="alert alert-success alert-dismissible" role="alert">
     Successfully
     <button type="button" class="btn-close"  aria-label="Close"></button>
   </div>
   <form name="" @submit.prevent="handleSubmit">
     <div class="row">
       <!-- Basic Layout -->
       <div class="col-md-12 col-sm-12">
         <div class="card">
           <div class="card-body">
             <div class="card-body">
               <div class="row mb-3">
                 <label
                   class="col-sm-12 col-form-label"
                   for="basic-icon-default-store"
                   >First Name 
                 </label>
                 <div class="col-sm-12">
                    <input type="text" class="form-control" id="basic-icon-default-fullname" placeholder="First Name" v-model="formData.firstname">
                 </div>
               </div>
               <div class="row mb-3">
                 <label
                   class="col-sm-12 col-form-label"
                   for="basic-icon-default-store"
                   >Last Name 
                 </label>
                 <div class="col-sm-12">
                    <input type="text" class="form-control" id="basic-icon-default-fullname" placeholder="Last Name" v-model="formData.lastname">
                 </div>
               </div>
               <div class="row mb-3">
                 <label
                   class="col-sm-12 col-form-label"
                   for="basic-icon-default-store"
                   >Phone 
                 </label>
                 <div class="col-sm-12">
                    <input type="text" class="form-control" id="basic-icon-default-fullname" placeholder="Phone" v-model="formData.phone" required>
                 </div>
               </div>
               <div class="row mb-3">
                 <label
                   class="col-sm-12 col-form-label"
                   for="basic-icon-default-store"
                   >National ID 
                 </label>
                 <div class="col-sm-12">
                    <input type="text" class="form-control" id="basic-icon-default-fullname" placeholder="NID" v-model="formData.national_id_no " >
                 </div>
               </div>
               <div class="row mb-3">
                 <label
                   class="col-sm-12 col-form-label"
                   for="basic-icon-default-store"
                   >Address 
                 </label>
                 <div class="col-sm-12">
                    <textarea type="text" class="form-control" id="basic-icon-default-fullname" placeholder="Address" v-model="formData.address" ></textarea>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
       <!-- <div class="col-md-4 col-sm-12">
         <div class="card">
           <div class="card-body">
             <div class="page-image">
               <img
                 src="https://www.imageeditexpert.com/uploads/images/about/about.png"
                 alt="n/a"
                 class="slider-setting-img img-fluid"
               />
             </div>
             <div class="mb-3">
               <label for="formFile" class="form-label">Image Choose</label>
               <input class="form-control" type="file" id="formFile" />
             </div>
             <div class="row justify-content-end">
               <div class="col-sm-12">
                 <button type="submit" class="btn btn-primary">Send</button>
               </div>
             </div>
           </div>
         </div>
       </div> -->
        <div class="mb-3 mt-4">
          <button type="submit" class="btn btn-primary w-100 justify-content-center" :disabled="submitBtn">
            <span v-if="!submitBtn">Save Changes</span> 
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="submitBtn"></span>
            <span class="visually-show" v-if="submitBtn"> Saving....</span>
          </button>
        </div>
     </div>
   </form>
 </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
// import CkEditor from "../../../components/Ckeditor.vue";
import axios from '../../../../node_modules/axios/index';
import { useStore } from 'vuex'; // Import useStore from Vuex
import { DOAMIN_PATH } from "@/api";

export default defineComponent({
name: "CategoryForm", // Rename the component to a multi-word name
 data() {
   const store = useStore();
   const token = store.state.auth.token || localStorage.getItem('token');
   const userId = store.state.auth.userId || localStorage.getItem('userId');
   return {
       successReport: false,
       itemId: '',
       store,
       token,
       userId, 
       infoReload: false,
       fetchProductList: [{
           id: Number,
           name: '',
       }],
       formData: {
         firstname:'',
         lastname:'',
         phone:'',
         address:'',
         national_id_no :'',
         facebook_id:'',
         google_id:'',
         profile_img:'',
       },
       submitBtn: false,
   }
 },

watch: {
 
  //if i back to this page the window will reload




 '$route.params.id': {
   immediate: true,
   handler(newVal) {
     if (newVal) {
       this.fetchData();
       this.itemId = newVal;
     }
   },
 },
},
methods: {
 async fetchData() {
   try {
     if (!this.token) {
       throw new Error('Token not found');
     }
     const id = this.userId;
     const response = await (axios as any).get(DOAMIN_PATH + `users/${id}`, {
       headers: {
         Authorization: `Bearer ${this.token}`,
       },
     });
     if (response.status === 200) {
       this.formData = response.data;
       this.store.commit('auth/SET_USER_DATA', JSON.stringify(this.formData));
        localStorage.setItem('userData', JSON.stringify(this.formData)); // Set userId in localStorage
        // const storedUserData = localStorage.getItem('userData') || this.store.state.auth.userData;
        if(this.infoReload) {
          this.infoReload = false;
          console.log('infoReload', this.infoReload);
          window.location.reload();
        }
     }
   } catch (error) {
     console.error(error);
   }
 },
 async handleSubmit() {
   try {
    this.submitBtn = true;
     if (!this.token) {
       throw new Error('Token not found');
     }
       const id = this.userId;
      console.log('_form_data', this.formData);
       const response = await (axios as any).put(
          DOAMIN_PATH + 'users/' + id,
          this.formData,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            }
          }
        );
       console.log('_response_', response);
       if(response.status == 200) {
        //  this.formData = response.data.data;
        this.infoReload = true;
        this.fetchData();
         this.successReport = true;
         this.submitBtn = false;
         setTimeout(() => { this.successReport = false; }, 3000);
       }
     
   } catch (error) {
     console.error(error);
     this.submitBtn = false;
   }
 }
},
mounted() {
 // Set isLoading to true when the component is mounted
 this.fetchData();
},
})
</script>