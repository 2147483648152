import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "layout-page" }
const _hoisted_2 = { class: "container-xxl" }
const _hoisted_3 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VarifyForm = _resolveComponent("VarifyForm")!
  const _component_Links = _resolveComponent("Links")!
  const _component_CustomerForm = _resolveComponent("CustomerForm")!
  const _component_ClaimForm = _resolveComponent("ClaimForm")!
  const _component_Status = _resolveComponent("Status")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          (_ctx.is_varify)
            ? (_openBlock(), _createBlock(_component_VarifyForm, {
                key: 0,
                onUpdateLink: _ctx.updateLink,
                onUpdateStatus: _ctx.updateStatus,
                "card-data": _ctx.cardData,
                onUpdateCard: _ctx.updateCardData
              }, null, 8, ["onUpdateLink", "onUpdateStatus", "card-data", "onUpdateCard"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", null, [
          (_ctx.is_link)
            ? (_openBlock(), _createBlock(_component_Links, {
                key: 0,
                onUpdateForm: _ctx.updateForm
              }, null, 8, ["onUpdateForm"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", null, [
          (_ctx.is_customer)
            ? (_openBlock(), _createBlock(_component_CustomerForm, {
                key: 0,
                onUpdateStatus: _ctx.updateStatus,
                "card-data": _ctx.cardData
              }, null, 8, ["onUpdateStatus", "card-data"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", null, [
          (_ctx.is_claim)
            ? (_openBlock(), _createBlock(_component_ClaimForm, {
                key: 0,
                onUpdateStatus: _ctx.updateStatus
              }, null, 8, ["onUpdateStatus"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", null, [
          (_ctx.is_status)
            ? (_openBlock(), _createBlock(_component_Status, {
                key: 0,
                onUpdateStatus: _ctx.updateBackHome
              }, null, 8, ["onUpdateStatus"]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}