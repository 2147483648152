<template>
      <div class="layout-page">
    <TopBarView />
    <div class="content-wrapper">
      <!-- <Search /> -->
      <div>
        <div>
          <div class="flex-grow-1 container-p-y px-4">
            <div class="d-flex justify-content-between aling-items-center">
              <div>
                <h4 class="fw-bold py-3 mb-4">
                    <span class="text-muted fw-light">Due</span> History
                </h4>
              </div>
              <div>
                <router-link to="/due" class="btn btn-primary mt-2" >Back to List</router-link>
                <!-- <button class="btn btn-primary mt-2" @click="toggleComponent" v-if="listShow">+ Add</button> -->
                <!-- <button class="btn btn-primary mt-2" @click="toggleComponent" v-if="formShow">Back to List</button> -->
              </div>
            </div>
            <!-- Content -->
            <div>
              <div>
                <div class="flex-grow-1 container-p-y px-4">
                  <!-- <div class="d-flex justify-content-between aling-items-center">
                    <div>
                      <h4 class="fw-bold py-3 mb-4 ps-2">
                          <span class="text-muted fw-light">Due History</span>
                      </h4>
                    </div>
                  </div> -->
                  <!-- Basic Layout & Basic with Icons -->
                  <div class="">
                    <!-- Basic Layout -->
                      <div class="">
                          <!-- table  -->
                        <div>
                          <div class="">
                            <div class="text-nowrap">
                              <div class="row">
                                <div class="">
                                  <div class=" py-3">
                                    <div class="d-flex justify-content-center align-items-center" v-if="loading">
                                      <div class="spinner-border text-primary" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                      </div>
                                    </div>
                                    <div class="row invoice-preview" v-else>                          
                                      
                                      <!-- /Invoice -->
                                      <div class="col-xl-9 col-md-8 col-12 mb-md-0 mb-4">
                                        <div class="card invoice-preview-card" id="invoice-container">
                                          <div class="card-body">
                                            <div class="row p-sm-3 p-0">
                                              <div class="col-xl-12 col-md-12 col-sm-7 col-12">
                                                <!-- <h6 class="pb-0"> <strong> Customer: </strong></h6> -->
                                                <table>
                                                  <tbody>
                                                    <tr>
                                                      <td class="pe-3"> <strong>নাম: </strong></td>
                                                      <td><strong>{{orderData?.customer?.name}}</strong></td>
                                                    </tr>
                                                    <!-- <tr>
                                                      <td class="pe-3">Type: </td>
                                                      <td>{{ orderData?.customer_type }}</td>
                                                    </tr> -->
                                                    <tr>
                                                      <td class="pe-3">ফোন :</td>
                                                      <td>{{ orderData?.customer?.phone }}</td>
                                                    </tr>
                                                    <tr>
                                                      <td class="pe-3">ঠিকানা:</td>
                                                      <td>{{ orderData?.customer?.address }}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>                                
                                          <hr class="my-0">
                                          <div class=" ">
                                            <div class="card-body">
                                              <p>প্রিয় : {{ orderData?.customer?.name }},</p>
                                              <p class=" text-wrap text-center">
                                                আশা করি আপনি ভাল আছেন। আসছে নতুন বছর, আগামি ২৭,২৮ শে ভাদ্র ১৪২৭ (ইং ১৩ ও ১৪ ই সেপ্টেম্বর) রবিবার ও সোমবার আমাদের দকানে নতুন হালখাতার শুভ উদ্ভদন করা হইবে। উক্ত শুভক্ষণে আপনার উপস্থিত কামনা করা হইল। 
                                              </p>
                                                <p class=" text-wrap text-center">
                                                গত বছরে আপনির লেনদেনগুলি আমাদের কাছে গুরুত্বপূর্ণ ছিল, আপনার সর্বমোট লেনদেন এর পরিমান ও বাকির পরিমানের বিবরন নিচে দেওয়া হল। 
                                              </p>
                                                                  
                                          <hr class="my-0">
                                              <div class="d-flex py-4 flex-sm-nowrap flex-wrap justify-content-between mt-4">
                                                <div class="">
                                                  <span class="border p-3 "> <strong>সর্বমোট বাকিঃ {{ Math.abs(orderData?.due) }} টাকা</strong> </span>
                                                </div>
                                                <div class="text-end mt-4 md-0">
                                                  <p class="mb-0"> শুভ কামনা,</p>
                                                  <p class="mb-0">{{ orderData?.store.name }}</p>
                                                  <p class="mb-0">{{ orderData?.store.address }}</p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        
                                          <div class="card-body">
                                            <div class="row">
                                              <div class="col-12">
                                                <!-- <span class="fw-medium">Note:</span> -->
                                                <span class="text-wrap">Design & Developed by <strong>PhicsArt</strong>! &nbsp;<img src="https://phicsart.com/uploads/images/top-page/menu/logo.svg" alt="" class="img-fluid " style="max-width: 13px;"> </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <!-- Invoice Actions  -->
                                      <div class="col-xl-3 col-md-4 col-12 invoice-actions">
                                        <div class="card mb-3">
                                          <div class="card-body">
                                            <button class="btn btn-primary d-grid w-100 mb-3" data-bs-toggle="offcanvas" data-bs-target="#sendInvoiceOffcanvas" @click="printInvoice('print')">
                                              <span class="d-flex align-items-center justify-content-center text-nowrap"><i class="bx bx-printer"></i> &nbsp; Print A4</span>
                                            </button>
                                            <button class="btn btn-label-secondary btn-default border d-grid w-100 mb-3" @click="downloadInvoice(orderData)">
                                              <span class="d-flex align-items-center justify-content-center text-nowrap"><i class="bx bx-download"></i> &nbsp; PDF Download</span>
                                            </button>
                                            <button class="btn btn-label-secondary border d-grid w-100 mb-3">
                                              <span class="d-flex align-items-center justify-content-center text-nowrap"><i class="bx bx-paper-plane bx-xs me-1"></i>Send Mail</span>
                                            </button>
                                          </div>
                                        </div>
                                      </div>                            
                                      <!-- /Invoice Actions -->
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- form..  -->                 
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <!-- / Footer -->
      <div class="content-backdrop fade"></div>
    </div>
    <!-- / Layout wrapper -->
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
// import CkEditor from "../../../components/Ckeditor.vue";
import axios from '../../../../node_modules/axios/index';
import { useStore } from 'vuex'; // Import useStore from Vuex
import { DOAMIN_PATH } from '@/api';
import Footer from "../../FooterView.vue";
import TopBarView from "../../TopBarView.vue";
import * as html2pdf from 'html2pdf.js';

export default defineComponent({
name: "due-form", // Rename the component to a multi-word name
components: { Footer, TopBarView},
data() {
  const store = useStore();
  const token = store.state.auth.token || localStorage.getItem('token');
  const userId = store.state.auth.userId || localStorage.getItem('userId');
  const companyId = store.state.auth.companyId || localStorage.getItem('companyId');
  const companyData = store.state.auth.defaultCompanyData || localStorage.getItem('defaultCompanyData');
  const baseURL = process.env.VUE_APP_BASE_URL;
 const apiUrl = process.env.VUE_APP_API_URL;
 const fileUrl = process.env.VUE_APP_FILE_URL;
  return {
       baseURL,
       apiUrl,
       fileUrl,
      successReport: false,
      companyId,
      itemId: '',
       store,
       token,
       userId, 
       companyData: JSON.parse(companyData),
       orderData: {
         id: 0,
         customer_id: '',
         user_id: '',
         company_id: '',
         invoice_no: "",
         customer_type: "",
         total: "",
         discount: "",
         sub_total: "",
         payment: "",
         due: Number(0),
         created_at: "",
         customer_phone: "",
         customer_name: "",
         customer_address: "",
         customer: {
           id: 14,
           name: '',
           phone: "",
           address: "",
           thumb: '',
           nid_no: '',
           date_of_birth: '',
         },
         order_items: [
           {
             id: 0,
             buy_price: "",
             sell_price: "",
             quantity: "",
             discount: "",
             total: "",
             order_id: "",
             product_id: "",
             product_name: "",
             customer_id: "",
             user_id: "",
             company_id: "",
           }
         ],
         store: {
           id: 0,
           name: "",
           email: null,
           phone: "",
           address: '',
           logo: '',
         },
       },
       loading: false,
  }
},

watch: {
'$route.params.id': {
  immediate: true,
  handler(newVal) {
    if (newVal) {
      this.fetchData(newVal);
      this.itemId = newVal;
    }
  },
},
},
methods: {
 async fetchData(id: number) {
     try {
       this.loading = true;
       if (!this.token) {
         throw new Error('Token not found');
       }
       const response = await (axios as any).get(DOAMIN_PATH + `order/${id}`, {
         headers: {
           Authorization: `Bearer ${this.token}`,  
           UserId: this.userId,
           // CompanyId: this.companyId,
         },
       });
       if (response.status === 200) {
         const orderData = response.data.data;
 
         // Format numerical properties to two decimal places
         orderData.total = parseFloat(orderData.total).toFixed(2);
         orderData.payment = parseFloat(orderData.payment).toFixed(2);
         orderData.discount = parseFloat(orderData.discount).toFixed(2);
         orderData.sub_total = parseFloat(orderData.sub_total).toFixed(2);
         this.orderData = orderData;          
         const date = new Date(this.orderData.created_at);
         const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
       // Assign the formatted date to the data property
         this.orderData.created_at = formattedDate,
         this.orderData.order_items = response.data.data.order_items.map((product: { sell_price: string; total: string; }) => ({
           ...product,
           sell_price: parseFloat(product.sell_price).toFixed(2),
           total: parseFloat(product.total).toFixed(2),
         }));
         this.loading = false;
       }
     } catch (error) {
       console.error(error);
       this.loading = false;
     }
   },
   async downloadInvoice(invoice: any) {
   const element = document.getElementById('invoice-container');
   const invoiceNumber = invoice.invoice_no;

   // Generate the PDF content using html2pdf
   const pdfBlob = await html2pdf(element).output('blob');

   // Create a Blob URL for the PDF content
   const blobUrl = URL.createObjectURL(pdfBlob);

   // Create a download link
   const downloadLink = document.createElement('a');
   downloadLink.href = blobUrl;

   // Set the download attribute with the desired filename
   downloadLink.download = `${invoiceNumber}.pdf`;

   // Append the download link to the document
   document.body.appendChild(downloadLink);

   // Trigger a click event on the download link to start the download
   downloadLink.click();

   // Wait for a short delay before removing the download link
   setTimeout(() => {
     // Remove the download link from the document
     document.body.removeChild(downloadLink);
   }, 100); // Adjust the delay as needed
 },

   async printInvoice(paperSize: string) {

     // Choose the template based on the selected paper size
     if (paperSize === '57mm') {       
       const element57mm = document.getElementById('invoice-container-57mm');
         if (element57mm) {
         // Create a new window and copy the content of the element
         const printWindow = window.open('');
         if (printWindow) {
           // printWindow.document.write('<html><head><link rel="icon" href="https://phicsart.com/uploads/images/top-page/menu/logo.svg"> <link rel="icon" type="image/x-icon" href="./assets/img/favicon/favicon.ico" /> <link rel="preconnect" href="https://fonts.googleapis.com" /><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin /><link href="https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet" /> <link rel="stylesheet" href="https://pos.phicsart.com/assets/vendor/fonts/boxicons.css" /><link rel="stylesheet" href="https://pos.phicsart.com/assets/vendor/css/core.css" class="template-customizer-core-css" /><link rel="stylesheet" href="https://pos.phicsart.com/assets/vendor/css/theme-default.css" class="template-customizer-theme-css" /> <link rel="stylesheet" href="https://pos.phicsart.com/assets/css/demo.css" /><link rel="stylesheet" href="https://pos.phicsart.com/assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css" /> <link rel="stylesheet" href="https://pos.phicsart.com/assets/vendor/libs/apex-charts/apex-charts.css" /> <title>Print</title></head><body>');
           // printWindow.document.write(element.innerHTML);
           // printWindow.document.write('</body></html>');
           // printWindow.document.close();

           // // Add a load event listener to ensure the content is fully loaded before printing
           // printWindow.onload = () => {
           //   printWindow.print();
           //   printWindow.onafterprint = () => {
           //     printWindow.close();
           //   };
           // };
          //  printWindow.document.write('<html><head><link rel="icon" href="https://phicsart.com/uploads/images/top-page/menu/logo.svg"> <link rel="icon" type="image/x-icon" href="./assets/img/favicon/favicon.ico" /> <link rel="preconnect" href="https://fonts.googleapis.com" /><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin /><link href="https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet" /> <link rel="stylesheet" href="https://pos.phicsart.com/assets/vendor/fonts/boxicons.css" /><link rel="stylesheet" href="https://pos.phicsart.com/assets/vendor/css/core.css" class="template-customizer-core-css" /><link rel="stylesheet" href="https://pos.phicsart.com/assets/vendor/css/theme-default.css" class="template-customizer-theme-css" /> <link rel="stylesheet" href="https://pos.phicsart.com/assets/css/demo.css" /><link rel="stylesheet" href="https://pos.phicsart.com/assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css" /> <link rel="stylesheet" href="https://pos.phicsart.com/assets/vendor/libs/apex-charts/apex-charts.css" /><title>Print</title>');
           // Add CSS styles for print media
           printWindow.document.write(`
             <style>
               @media print {
                 body {
                   margin: 20px; /* Reset margin for print */
                   font-size: 10px !important; /* Adjust the font size as needed */
                   width: 57mm; /* Ensure the content fits within the paper size */
                 }

                 /* Add more print styles here based on the paper size */
                 @page {
                   size: 80mm; /* Set the paper size */
                   margin: 20px; /* Set margins as needed */
                 }

                 /* Add other print styles specific to your design */
                 /* Example: */
                 #${element57mm} {
                   width: 80mm; /* Ensure the content fits within the paper size */
                   font-size: 8px; /* Adjust the font size as needed */
                 }
                 #invoice-container-80mm p, h4{
                   font-size: 10px;
                 }
                 #invoice-container-80mm h4{
                 margin-bottom: 0;
                 padding: 0;
                 }
                 .card {
                     background-clip: padding-box;
                     box-shadow: none;
                 }
                 .card-body{
                 margin: 0;
                 padding: 0;
                 }
                 .card-body h6{
                 margin: 0;
                 padding: 0;
                 font-size: 10px;
                 }
                 .table > thead{
                   border-style: hidden;
                   color: black;
                 }
                 .table:not(.table-dark) th{
                   color: black;
                 }
                 .table > tr > td{
                   border-style: unset;
                   border-width: 0;
                   font-size: 10px;
                   font-weight: 1000;
                   color: black;
                   border-bottom-width: 0px;
                 }
                 .table > :not(caption) > * > *{
                   border-style: hidden;
                   border-bottom-width: 0px;
                 }
                 .table > :not(caption) > * > *{
                   padding: 5px 0;
                   font-size: 10px;
                   color: black;
                 }
               }
             </style>
           `);

           // Copy the content of the element
           printWindow.document.write('</head><body>');
           printWindow.document.write(element57mm.innerHTML);
           printWindow.document.write('</body></html>');
           printWindow.document.close();
           // Add a load event listener to ensure the content is fully loaded before printing
           printWindow.onload = () => {
             printWindow.print();
             printWindow.onafterprint = () => {
               printWindow.close();
             };
           };
         }
         }
     } 
     // ... rest of your code remains unchanged ...
   }
},
mounted() {
// Set isLoading to true when the component is mounted
//  console.log('_user_id_', this.companyData);
//  console.log('Base URL:', this.baseURL);
//   console.log('API URL:', this.apiUrl);
//   console.log('File URL:', this.fileUrl);
 },
})
</script>