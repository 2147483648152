<template>
  <div>
    <!-- search form start  -->
    <div class="card mb-4">
      <div class="card-body">    
        <div class="row">
          <div class="input-group">
            <!-- <div class="input-group-prepend">
              <select class="form-select" aria-label="Default select example">
                    <option selected>Search By-</option>
                    <option value="1">Category</option>
                    <option value="2">Product Name </option>
              </select>  
            </div> -->
            <span class="input-group-text" id="basic-addon1">From</span>
            <input type="date" class="form-control" placeholder="Enter Search Key !" v-model="fromDate">
            <span class="input-group-text" id="basic-addon1">To</span>
            <input type="date" class="form-control" placeholder="Enter Search Key !" v-model="toDate">
            <input type="text" class="form-control w-25" placeholder="Enter Search Key !" v-model="searchKey">
            <button type="button" class="btn btn-primary me-1 ms-1" @click="searchData"><font-awesome-icon :icon="['fas', 'magnifying-glass']" /></button>
            <button type="button" class="btn btn-default border" @click="searchKey = '', fromDate = '', toDate = '', fetchAllData()"><font-awesome-icon :icon="['fas', 'broom']" /></button>               
          </div>
        </div>
      </div>
    </div>
    <!-- search form end  -->
    <!-- table start  -->
    <div class="card mb-4 d-none d-md-block">
      <div class="card-body">
        <div class="d-flex justify-content-center align-items-center" v-if="loading">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="table-responsive text-nowrap" v-else>
          <table class="table card-table" v-if="fettchDataArray.length > 0">
            <thead>
              <tr>
                <th>Id</th>
                <!-- <th v-if="userData?.type=='SUPERADMIN'">Company</th> -->
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(dataItem, index) in fettchDataArray" :key="dataItem.id" v-show="dataItem.type !== 'SUPERADMIN'">
                <td v-if="dataItem.type !== 'SUPERADMIN'">{{ getRowIndex(index) }}</td>
                <!-- <td v-if="userData?.type=='SUPERADMIN'">{{ dataItem.store?.name }}</td> -->
                <td>{{ dataItem.firstname }} {{ dataItem.lastname }}</td>
                <td>{{ dataItem.phone }}</td>
                <td>{{ dataItem.email }}</td>
                <td>{{ formatDateTime(dataItem.created_at) }}</td>
                <td>
                  <button type="button" class="me-2 btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#viewMoreModal" @click="viewItem(dataItem)"><i class="fa fa-eye" aria-hidden="true"></i></button>
                  <button type="button" class="me-2 btn btn-sm btn-warning" @click="editItem(dataItem.id.toString())" v-if="userData?.type=='SUPERADMIN'"> 
                    <font-awesome-icon :icon="['fas', 'pen-to-square']" />
                  </button>
                  <!-- <button type="button" class="me-2 btn btn-sm btn-danger" data-bs-toggle="modal" data-bs-target="#staticBackdrop" @click="deleteclick(dataItem.id, dataItem.user_id)">
                    Delete
                  </button> -->
                  <button type="button" class="me-2 btn btn-sm btn-danger" 
                    data-bs-toggle="modal" data-bs-target="#modalCenter"
                    @click="fetchItem(dataItem)"
                  >
                    <font-awesome-icon :icon="['fas', 'trash-can']" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-else>
            <h5 class="text-center mb-0">There Is No Customer</h5>
          </div>
        </div>
      </div> 
    </div>
    <div class="mobile-view d-block d-md-none">
      <div  v-if="fettchDataArray.length > 0">
        <div class="card mb-4" v-for="dataItem in fettchDataArray" :key="dataItem.id">
          <div class="card-body">
          <small> Name: {{ dataItem.firstname }} {{ dataItem.lastname }}</small><br/>
          <small>Phone: {{ dataItem.phone }}</small>
          <p class="text-wrap mb-0">Email: {{ dataItem.email }}</p>
          <p class="text-wrap">Date: {{ formatDateTime(dataItem.created_at) }}</p>
          <button type="button" class="me-2 btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#viewMoreModal" @click="viewItem(dataItem)"><i class="fa fa-eye" aria-hidden="true"></i></button>
          <button type="button" class="me-2 btn btn-sm btn-warning" @click="editItem(dataItem.id.toString())" v-if="userData?.type=='SUPERADMIN'">
            <font-awesome-icon :icon="['fas', 'pen-to-square']" />
          </button>
          <button type="button" class="me-2 btn btn-sm btn-danger" 
            data-bs-toggle="modal" data-bs-target="#modalCenter"
            @click="fetchItem(dataItem)"
          >
          <font-awesome-icon :icon="['fas', 'trash-can']" />
          </button>
          </div>
        </div>      
      </div>
      <div v-else>
        <div class="card">
          <div class="card-body">
            <h5 class="text-center mb-0 text-wrap">There Is No Customer</h5>  
          </div>
        </div> 
      </div>
    </div>
    <!-- // table end -->
    <!-- pagination start  -->
    <div class="" v-if="fettchDataArray.length > 0">
      <div class="d-flex justify-content-between flex-wrap">
        <nav aria-label="Page navigation">
          <ul class="pagination mb-0 flex-wrap">
            <!-- Previous button -->
            <!-- <li class="page-item" @click="previousPage">
              <a class="page-link"><i class="tf-icon bx bx-chevron-left"></i></a>
            </li> -->

            <!-- Page buttons -->
              <li v-for="(page, index) in paginationData.links" :key="index" class="page-item myPagination m-1" :class="{ active: page.active }">
                <button class="page-link" @click="goToPage(Number(page.label))" v-html="page.label"></button>
              </li>
            <!-- Next button -->
            <!-- <li class="page-item" @click="nextPage">
              <a class="page-link"><i class="tf-icon bx bx-chevron-right"></i></a>
            </li> -->
          </ul>
        </nav>
        <div class="row">
          <div class="col-12">
            <div class="input-group ">
              <label class="input-group-text" for="inputGroupSelect01">Size</label>
              <select class="form-select" id="inputGroupSelect01" v-model="pageSize" @change="fetchData">
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
        </div>
      </div> 
      <div class="mt-4 ">
          <span class="p-3 bg-light"> Showing: {{ paginationData.from }} - {{ paginationData.to }} of {{ paginationData.total }}</span>
        </div>
    </div>
    <!-- // pagination end -->
    <!-- Delete Confirm Modal -->
    <div class="modal fade" id="modalCenter" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header ">
            <h5 class="modal-title text-center" id="modalCenterTitle">Delete Confirmation !</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body text-center">  
            <i class="bx bx-bell bx-lg text-danger mb-3 d-inline-block"></i>      
            <p>Are You Sure to Delete ?</p>
            <p><b>{{ deletevalue.name }}</b> </p>
          </div>
          <div class="modal-footer text-center justify-content-center">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
              Close
            </button>
            <button data-bs-dismiss="modal" type="button" class="btn btn-danger" @click = "deleteItem(deletevalue.id)" disabled>Confirm</button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal end  -->
    <!-- view more modal  -->
    <div class="modal fade" id="viewMoreModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header alert alert-primary">
            <h5 class="modal-title mb-3" id="staticBackdropLabel" style="color: #696cff;">Customer Details</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <table class="table" style="border-color: transparent;">
                <tbody>
                  <tr>
                    <th style="width: 20%;">Name</th>
                    <th style="width: 2%;">:</th>
                    <td>{{ viewDataArray.firstname }} {{ viewDataArray.lastname }}</td>
                  </tr>
                  <tr>
                    <th style="width: 20%;">Phone</th>
                    <th style="width: 2%;">:</th>
                    <td>{{ viewDataArray.phone}}</td>
                  </tr>
                  <tr>
                    <th style="width: 20%;">Email</th>
                    <th style="width: 2%;">:</th>
                    <td>{{ viewDataArray.email}}</td>
                  </tr>
                  <tr>
                    <th style="width: 20%;">Address</th>
                    <th style="width: 2%;">:</th>
                    <td>{{ viewDataArray.address }}</td>
                  </tr>
                  <tr>
                    <th style="width: 20%;">Created At</th>
                    <th style="width: 2%;">:</th>
                    <td>{{ formatDateTime(viewDataArray.created_at) }}</td>
                  </tr>
                </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue';
import axios from '../../../../node_modules/axios/index';
import { useStore } from 'vuex'; // Import useStore from Vuex
import { DOAMIN_PATH } from "@/api";
interface mappingData {
  id: number;
  email: string;
  emailVerifiedAt: string; // ISO 8601 date string
  phone: string;
  firstname: string;
  lastname: string;
  profileImg: string; // URL or empty string
  nationalIdNo: string; // empty string or national ID number
  address: string; // empty string or user address
  type: 'SUPERADMIN' | 'ADMIN' | 'USER'; // or other possible types
  created_at: string; // ISO 8601 date string
  updated_at: string; // ISO 8601 date string
}
export default defineComponent({
  name: "CategoryList", // Rename the component to a multi-word name
  data() {
    const store = useStore();
    const token = store.state.auth.token || localStorage.getItem('token');
    const userDataArray = store.state.auth.userData || localStorage.getItem('userData');
    const userData = JSON.parse(userDataArray);
    return {      
      store,
      token,
      userData,
      searchKey: '',  
      fromDate: '',
      toDate: '',    
      rowIndex: 0,
      fettchDataArray: ref([] as mappingData[]), // Explicitly define the type as Post[] // Explicitly define the type as Post[]
      viewDataArray: {
        id: 0,
        email: "",
        emailVerifiedAt: "",
        phone: "",
        firstname: "",
        lastname: "",
        profileImg: "",
        nationalIdNo: "",
        address: "",
        type: "",
        created_at: "",
        updated_at: "",
      },
      deletevalue: {
        id: 0,
        name: "",
      },
      isDataTrueList: true,
      id: '',
      loading: false,
      pageSize: 10, // set a default value
      currentPage: 1, // set a default value
      paginationData: {
        links: [
          {
            label: '',
            url: '',
            active: '',
          }
        ],
        prev_page: 0,
        from: 0,
        to: 0,
        next_page: 0,
        total: 0,
        per_page: 0,
        current_page: 0,
        last_page: 0,

      }, // Object to store pagination data
    };
  },
  methods: {
    async fetchAllData() {
      try {
        this.loading = true;
        if (!this.token) {
            throw new Error('Token not found');
          }
          const response = await axios.get(DOAMIN_PATH + 'customer',
          {
            params: {
              page: this.currentPage,
              size: this.pageSize,
            },
            headers: {
              Authorization: `Bearer ${this.token}`,
            }
          }
          );
        if (response.status === 200) {
          this.fettchDataArray = response.data.data;
          this.paginationData = response.data;
          this.loading = false;
        }
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },

    // Delete areas data
    async fetchItem(item: any) {
      this.deletevalue = item;
      console.log("deletevalue", this.deletevalue);
    },
    async deleteItem(id: number) {
      try {
        const response = await (axios as any).delete(DOAMIN_PATH + 'customer/'+id, {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          });

        if (response.status == 200) {
          console.log('_delete_success', response);
          this.fetchAllData();
        }
      } catch (error) {
        console.error(error);
      }
    },
    async viewItem(data: any) {
      this.viewDataArray = data;
    },
    formatDateTime(isoString: string | number | Date) {
      const date = new Date(isoString);

      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        // timeZoneName: 'short',
      };

      return date.toLocaleString(undefined, options);
    },
        sendData(id: string) {
          this.$emit('data-emitted', {
            isDataTrueList: this.isDataTrueList,
            id,
          });
        },

    editItem(id: any) {
      // Navigate to the edit route with the ID parameter
      this.$router.push({ params: { id } });
    },
    // pagination function
    async fetchData() {
    // Optionally, you can reset the current page to 1 when the page size changes
    // this.currentPage = 1;
    // await this.fetchAllData();
    },
    goToPage(pageOrLabel: string | number | null) {
      
      if (pageOrLabel === "Next »" || pageOrLabel === "&laquo; Previous") {
        console.log('Skipping Next/Previous');
        return;
      }
      if (typeof pageOrLabel === 'number') {
        console.log('Setting currentPage  :', pageOrLabel);
        this.currentPage = pageOrLabel;
        console.log('curentPage:', this.currentPage);
        // this.fetchAllData();
      } else if (pageOrLabel) {
        const match = pageOrLabel.match(/page=(\d+)/);
        if (match) {
          console.log('else id match:', parseInt(match[1]));
          this.currentPage = parseInt(match[1]);
        }
      }

      // this.fetchAllData();
    },
    // Add methods to navigate to the previous and next pages
    previousPage() {
      if (this.paginationData.current_page > 1) {
        this.currentPage = this.paginationData.current_page - 1;
        // this.fetchAllData();
      }
    },

    nextPage() {
      if (this.paginationData.current_page < this.paginationData.last_page) {
        this.currentPage = this.paginationData.current_page + 1;
        // this.fetchAllData();
      }
    },
    async searchData() {
      try {
        this.loading = true;
        const response = await (axios as any).get(DOAMIN_PATH + 'customer',
          {
            params: {
              search: this.searchKey,
              from_date: this.fromDate,
              to_date: this.toDate
            },
            headers: {
              Authorization: `Bearer ${this.token}`,
            }
          }
        );
        if (response.status == 200) {
          this.fettchDataArray = response.data;
          this.paginationData = response.data;
          this.loading = false;
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {
  getRowIndex() {
    return (index: number) => {
      let rowIndex = 0;
      for (let i = 0; i < this.fettchDataArray.length; i++) {
        if (this.fettchDataArray[i].type !== 'SUPERADMIN') {
          rowIndex++;
        }
        if (i === index) {
          return rowIndex;
        }
      }
    }
  }
},
  mounted() {
    this.fetchAllData();
  },
});
</script>