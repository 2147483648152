import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import DashboardView from '@/views/Dashboard/DashboardView.vue';
// import ForgetPasswordView from '@/views/ForgetPasswordView.vue';
import FeaturesSetting from '@/views/Features/FeaturesSetting.vue';
import FeaturesForm from '@/views/Features/Components/Form.vue';
import Product from '@/views/Product/ProductView.vue';
import ProductForm from '@/views/Product/Components/Form.vue';
import Customer from '@/views/Customer/CustomerView.vue';
import CustomerForm from '@/views/Customer/Components/Form.vue';
import Sale from '@/views/Sale/SaleView.vue';
import Order from '@/views/Order/OrderView.vue';
import OrderForm from '@/views/Order/Components/Form.vue';
import OrderItem from '@/views/OrderItem/OrderItemView.vue';
import Stock from '@/views/Stock/StockView.vue';
import StockSummary from '@/views/Stock/Components/StockSummary.vue';
import Setting from '@/views/Settings/SettingView.vue';
import SecuritySetting from '@/views/SecuritySetting/SettingView.vue';
import User from '@/views/Users/UserView.vue';
import Warranty from '@/views/Warranty/WarrantySetting.vue';
import WarrantyStatus from '@/views/WarrantyStatus/WarrantyStatus.vue';
import Due from '@/views/Due/DueView.vue';
import DueForm from '@/views/Due/Components/Form.vue';
import Supplier from '@/views/Supplier/SupplierView.vue';
import SupplierForm from '@/views/Supplier/Components/Form.vue';
import Type from '@/views/Type/TypeView.vue';
// import TypeForm from '@/views/Type/Components/Form.vue';
import Payment from '@/views/Payments/PaymentView.vue';
import BarcodeView from '@/views/BarcodePage/Barcode.vue';
import Store from '@/views/Store/StoreView.vue';
import StoreForm from '@/views/Store/Components/Form.vue';
import StoreView from '@/views/Store/Components/ViewData.vue';
import Invoice from '@/views/InvoiceView.vue';
import NotFound from '@/views/NotFound.vue';
import { useStore } from 'vuex';
const store = useStore();


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Dashboard',
    component: DashboardView,
    meta: { requiredScopes: ['DASHBOARD'] },
  },
  {
    path: '/features_setting',
    name: 'features-setting',
    component: FeaturesSetting,
    meta: { requiredScopes: ['FEATURES'] },
  },
  {
    path: '/features_setting/form/:id?',
    name: 'features-form',
    component: FeaturesForm,
    meta: { requiredScopes: ['FEATURES'] },
  },
  {
    path: '/product',
    name: 'product-view',
    component: Product,
    meta: { requiredScopes: ['PRODUCT'] },
    
  },
  {
    path: '/product/form/:id?',
    name: 'product-form',
    component: ProductForm,
    meta: { requiredScopes: ['PRODUCT'] },
    
  },
  {
    path: '/barcode',
    name: 'barcode-view',
    component: BarcodeView,
    meta: { requiredScopes: ['BARCODE'] },
    
  },
  {
    path: '/barcode/form/:id?',
    name: 'barcode-summary',
    component: ProductForm,
    meta: { requiredScopes: ['BARCODE'] },
    
  },
  {
    path: '/customer',
    name: 'customer-view',
    component: Customer,
    meta: { requiredScopes: ['CUSTOMER'] },
  },
  {
    path: '/customer/form/:id?',
    name: 'customer-form',
    component: CustomerForm,
    meta: { requiredScopes: ['CUSTOMER'] },
  },
  {
    path: '/sale/:id?',
    name: 'sale-view',
    component: Sale,
    meta: { requiredScopes: ['SALE'] },
  },
  {
    path: '/order',
    name: 'order-view',
    component: Order,
    meta: { requiredScopes: ['ORDER'] },
  },
  {
    path: '/order/form/:id?',
    name: 'order-form',
    component: OrderForm,
    meta: { requiredScopes: ['ORDER'] },
  },
  {
    path: '/order_item',
    name: 'Order-item-view',
    component: OrderItem,
    meta: { requiredScopes: ['ORDER'] },
  },
  {
    path: '/stock',
    name: 'stock-view',
    component: Stock,
    meta: { requiredScopes: ['STOCK'] },
  },
  {
    path: '/stock/summary/:id?',
    name: 'stock-summary',
    component: StockSummary,
    meta: { requiredScopes: ['STOCK'] },
  },
  {
    path: '/setting',
    name: 'setting-view',
    component: Setting,
    meta: { requiredScopes: ['SETTING'] },
  },
  {
    path: '/securitysettings',
    name: 'security-setting-view',
    component: SecuritySetting,
    meta: { requiredScopes: ['SETTING'] },
  },
  {
    path: '/users',
    name: 'user-view',
    component: User,
    meta: { requiredScopes: ['USER'] },
  },
  {
    path: '/due',
    name: 'due-view',
    component: Due,
    meta: { requiredScopes: ['DUE'] },
  },
  {
    path: '/due/form/:id?',
    name: 'due-form',
    component: DueForm,
    meta: { requiredScopes: ['DUE'] },
  },
  {
    path: '/supplier',
    name: 'supplier-view',
    component: Supplier,
    meta: { requiredScopes: ['SUPPLIER'] },
  },
  {
    path: '/supplier/form/:id?',
    name: 'supplier-form',
    component: SupplierForm,
    meta: { requiredScopes: ['SUPPLIER'] },
  },
  {
    path: '/warranty',
    name: 'warranty-setting',
    component: Warranty,
  },
  {
    path: '/warranty-status',
    name: 'warranty-status-view',
    component: WarrantyStatus,
    meta: { requiredScopes: ['WARRANTY'] },
  },
  // {
  //   path: '/type',
  //   name: 'type-view',
  //   component: Type,
  //   meta: { showSpinner: true },    
  //   beforeEnter: (to, from, next) => {
  //     const userData = localStorage.getItem('userData') || '{}';
  //     const parsedUserData = JSON.parse(userData);
  //     console.log(parsedUserData.type);      
  //     if (parsedUserData.type === 'SUPERADMIN' || to.matched.some(record => record.meta.requiresSuperAdmin)) {
  //       // console.log('Access grated');
  //       next();
  //     } else {
  //       // console.log('Access denied');
  //       next('/');
  //     }
  //   },
      
  // },
  // {
  //   path: '/type/form/:id?',
  //   name: 'tyoe-form',
  //   component: Type,
  //   meta: { showSpinner: true },    
  //   beforeEnter: (to, from, next) => {
  //     const userData = localStorage.getItem('userData') || '{}';
  //     const parsedUserData = JSON.parse(userData);
  //     console.log(parsedUserData.type);      
  //     if (parsedUserData.type === 'SUPERADMIN' || to.matched.some(record => record.meta.requiresSuperAdmin)) {
  //       // console.log('Access grated');
  //       next();
  //     } else {
  //       // console.log('Access denied');
  //       next('/');
  //     }
  //   },
      
  // },
  {
    path: '/payments',
    name: 'payment-view',
    component: Payment,
    meta: { requiredScopes: ['PAYMENT'] },
  },
  {
    path: '/store',
    name: 'store-view',
    component: Store,
    meta: { requiredScopes: ['STORE'] },
  },
  {
    path: '/store/form/:id?',
    name: 'store-form',
    component: StoreForm,
    meta: { requiredScopes: ['STORE'] },
  },
  {
    path: '/store/view/:id?',
    name: 'store-view-data',
    component: StoreView,
    meta: { requiredScopes: ['STORE'] },
  },
  {
    path: '/invoice/:id?',
    name: 'invoice-view',
    component: Invoice,
    meta: { showSpinner: true }
  },
    // Wildcard route for 404 page
    {
      path: '/:catchAll(.*)',
      name: 'not-found',
      component: NotFound,
    },
];
// This is a navigation guard to restrict access to the /dashboard route

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Assuming you're using Vuex to manage user data


router.beforeEach((to, from, next) => {

  const unrestrictedRoutes = ['/warranty', '/setting', '/securitysettings'];

  // Allow access to unrestricted routes directly
  if (unrestrictedRoutes.includes(to.path)) {
    return next(); // Skip checks for unrestricted routes
  }

  // Check if the user is authenticated
  const store = useStore();
  const userData = localStorage.getItem('userData') || store.state.auth.userData;
  const userScopes = userData ? JSON.parse(userData).scopes.split(',') : [];
  // Define routes that don't require authentication or scopes

  // Check required scopes for other routes
  const requiredScopes = to.meta?.requiredScopes;

  if (Array.isArray(requiredScopes)) {
    const hasAccess = requiredScopes.every((scope: string) => userScopes.includes(scope));

    if (!hasAccess) {
      // console.log('Access denied. Redirecting to "not-found" page.');
      return next('/404'); // Redirect to 404 page if access denied
    }
  }

  next(); // Allow navigation if no restrictions or access granted
});







export default router;


