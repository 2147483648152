  <template >
    <div class="">
      <!-- <TopBarView /> -->
      <div class="content-wrapper d-flex align-items-center justify-content-center" style="min-height: 100vh; width: 100%">
        <!-- <Search /> -->
        <!-- Content -->
        <div class="w-100">
          <div>
            <div class="flex-grow-1 container-p-y px-4">
              <div class="col-md-9 col-sm-12 mx-auto">
                <div class="row h-100">
                  <div class="col-md-6 mx-auto">
                    <div class="h-100">
                      <div class="d-flex align-items-center justify-content-center h-100">
                        <div class="w-100">
                          <div class="authentication-wrapper authentication-basic container-p-y">
                            <div class="authentication-inner w-100">
                              <!-- login -->
                              <div class="card">
                                <div class="card-body">
                                  <!-- Logo -->
                                  <div class="app-brand justify-content-center">
                                    <a href="/" class="app-brand-link gap-2">
                                      <span class="app-brand-text demo text-body fw-bold text-uppercase mb-3"> 
                                        <img src="https://pos-file.phicsart.com/profile_user.png" alt="n/a" class="img-fluid" style="width:80px; height:80px;">
                                      </span>
                                    </a>
                                  </div>
                                  <!-- /Logo -->
                                  <h4 class="mb-2 text-center mb-5">Raida Trade International 👋</h4>
                                    <div class="mb-3">
                                        <div class="row">
                                          <div class="col-12">
                                            <!-- varified correct icon  -->
                                            <div class="text-center">
                                              <div class="row">
                                                <!-- font awsome checked icon -->
                                                <i class="fa fa-check" aria-hidden="true" style="font-size: 100px; color: green;"></i>
                                              </div>
                                             </div>
                                             <h3 class="text-center mt-2">Varified !</h3>
                                          </div>
                                          <div class="col-12">
                                            <button type="submit" class="btn btn-primary w-100 my-3 btn-lg justify-content-center" @click="activationHandle">
                                              Activate Now
                                            </button>   
                                          </div>
                                          <!-- <div class="col-12">
                                            <button type="submit" class="btn btn-primary w-100 my-3 btn-lg justify-content-center" @click="claimHandle" >
                                              Warranty Claim
                                            </button>
                                          </div> -->
                                        </div>
                                    </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  
            </div>
          </div>
        </div>
        <!-- <Footer /> -->
        <!-- / Footer -->

        <div class="content-backdrop fade"></div>
      </div>
      <!-- / Layout wrapper -->
    </div>
  </template>

  <script lang="ts">
  import { defineComponent, ref } from "vue";
  import CustomerForm from "./CustomerForm.vue";
  // import Footer from "../FooterView.vue";
  // import TopBarView from "../TopBarView.vue";
  // import { useStore } from "vuex";
  export default defineComponent({
    // components: { Footer, TopBarView},
    name: "warranty-setting",
    data() {
      // const store = useStore();
      // let userData = store.state.auth.userData || localStorage.getItem('userData');
      // userData = JSON.parse(userData);
      return {
        errorMessages: ref({ message: '' }),
        submitBtn: ref(false),

      };
    },
    watch: {

    },
    methods: {
      activationHandle() {
        this.$emit('update-form', 'customer');
      },
      claimHandle() {
        this.$emit('update-form', 'claim');
      },
    },

    mounted() {
      // Set isLoading to true when the component is mounted
    }
  });
  </script>