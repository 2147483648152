<template>
    <div>
      <!-- search form start  -->
      <div class="card mb-4">
        <div class="card-body">    
          <div class="row">
            <div class="input-group">
              <div class="input-group-prepend">
                <select class="form-select" aria-label="Default select example" v-model="status">
                      <option value="">--Select Status--</option>
                      <option value="0">Initial</option>
                      <option value="1">Printed </option>
                      <option value="2">Dispatch </option>
                      <option value="3">In Store </option>
                      <option value="4">Activated </option>
                      <option value="5">--5-- </option>
                      <option value="6">--6-- </option>
                      <option value="7">--7-- </option>
                </select>  
              </div>
              <div class="input-group-prepend">
                <select class="form-select" aria-label="Default select example" v-model="productId">
                      <option value="">--Select product--</option>
                      <option v-for="product in fettchDataProductArray" :key="product.id" :value="product.id">{{ product.name }}</option>
                </select>  
              </div>
              <span class="input-group-text" id="basic-addon1">From</span>
              <input type="date" class="form-control" placeholder="Enter Search Key !" v-model="fromDate">
              <span class="input-group-text" id="basic-addon1">To</span>
              <input type="date" class="form-control" placeholder="Enter Search Key !" v-model="toDate">
              <input type="text" class="form-control w-25" placeholder="Enter Search Key !" v-model="searchKey">
              <button type="button" class="btn btn-primary me-1 ms-1" @click="searchData"><font-awesome-icon :icon="['fas', 'magnifying-glass']" /></button>
              <button type="button" class="btn btn-default border" @click="searchKey = '', fromDate = '', toDate = '', status = '', productId = '', fetchData()"><font-awesome-icon :icon="['fas', 'broom']" /></button>               
            </div>
          </div>
        </div>
      </div>
      <!-- search form end  -->
      <!-- table start  -->
      <div class="card mb-4 d-none d-md-block">
        <div class="card-body">
          <div class="d-flex justify-content-center align-items-center" v-if="loading">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div class="table-responsive" v-else>
            <table class="table card-table text-center" v-if="fettchDataArray.length > 0">
            <thead>
                <tr>
                <th>Id</th>
                <!-- <th v-if="userData?.type=='SUPERADMIN'">Company</th> -->
                <th>Code</th>
                <th>Barcode</th>
                <!-- <th>Quantity (WH | DE)</th> -->
                <th>Status</th>
                <th>Action</th>
                </tr>
            </thead>
            <tbody class="table-border-bottom-0">
                <tr v-for="(dataItem, index) in fettchDataArray" :key="dataItem.id">
                <td>{{ index + 1 }}</td>
                <!-- <td v-if="userData?.type=='SUPERADMIN'">{{ dataItem.store?.name }}</td> -->
                <td>{{ dataItem.barcode }}</td>
                <td><svg :ref="el => barcodeRefs2[dataItem.id] = el" style="width: 30px; height: 10px;"></svg></td>
                <td> 
                  <span v-if="dataItem.status == 0">Initial ({{ dataItem.status }})</span> 
                  <span v-if="dataItem.status == 1">Printed ({{ dataItem.status }})</span> 
                  <span v-if="dataItem.status == 2">Dispatch ({{ dataItem.status }})</span> 
                  <span v-if="dataItem.status == 3">In Store ({{ dataItem.status }})</span> 
                  <span v-if="dataItem.status == 4">Activated ({{ dataItem.status }})</span> 
                  <span v-if="dataItem.status == 5">Claim ({{ dataItem.status }})</span> 
                </td>
                <td>
                    
                    <button type="button" class="me-2 btn btn-sm btn-primary btn-disable" data-bs-toggle="modal" data-bs-target="#viewModal" @click="viewData(dataItem.id)"> <font-awesome-icon :icon="['fas', 'eye']" /></button>
                    <!-- <div class="custom-dropdown">
                      <button class="dropdown-button" @click="toggleDropdown(dataItem.id)">
                        Option
                      </button>
                      <ul class="dropdown-menu" :class="{ 'show-dropdown': showDropdown }">
                        <li v-for="(item, index) in dropdownItems" :key="index">
                          <a href="#" @click="handleClick(item)">{{ item }}</a>
                        </li>
                      </ul>
                    </div> -->
                    <!-- option button will be dorpdown like regenerate and delete  -->

                    <!-- <button type="button" class="me-2 btn btn-sm btn-secondary btn-disable"> Summary </button> -->
                </td>
                </tr>
            </tbody>
            </table>
            <div v-else>
              <h5 class="text-center mb-0">There Is No Barcode</h5>
            </div>
          </div>
        </div> 
      </div>
      <div class="mobile-view d-block d-md-none">
      <div  v-if="fettchDataArray.length > 0">
        <div class="card mb-4" v-for="dataItem in fettchDataArray" :key="dataItem.id">
          <div class="card-body">
          <small> Code: {{ dataItem.barcode }}</small><br/>
          <small>Barcode: <svg :ref="(el) => barcodeRefs[dataItem.id] = el" style="width: 30px; height: 10px;"></svg></small>
          <p class="text-wrap ">Status: 
            <span v-if="dataItem.status == 0">Initial ({{ dataItem.status }})</span> 
            <span v-if="dataItem.status == 1">Printed ({{ dataItem.status }})</span> 
            <span v-if="dataItem.status == 2">Dispatch ({{ dataItem.status }})</span> 
            <span v-if="dataItem.status == 3">In Store ({{ dataItem.status }})</span> 
            <span v-if="dataItem.status == 4">Activated ({{ dataItem.status }})</span> 
            <span v-if="dataItem.status == 5">Claim ({{ dataItem.status }})</span> 
          </p>
          <!-- <button type="button" class="me-2 btn btn-sm btn-warning" @click="editItem(dataItem.id.toString())">
            <font-awesome-icon :icon="['fas', 'pen-to-square']" />
          </button>
          <button type="button" class="me-2 btn btn-sm btn-danger" 
            data-bs-toggle="modal" data-bs-target="#modalCenter"
            @click="fetchItem(dataItem)"
          >
          <font-awesome-icon :icon="['fas', 'trash-can']" />
          </button> -->
          <button type="button" class="me-2 btn btn-sm btn-primary btn-disable" data-bs-toggle="modal" data-bs-target="#viewModal" @click="viewData(dataItem.id)"> <font-awesome-icon :icon="['fas', 'eye']" /></button>
          <button type="button" class="me-2 btn btn-sm btn-primary btn-disable"> Option </button>
          </div>
        </div>      
      </div>
      <div v-else>
        <div class="card">
          <div class="card-body">
            <h5 class="text-center mb-0 text-wrap">There Is No Barcode</h5>  
          </div>
        </div> 
      </div>
    </div>
      <!-- // table end -->

      <!-- pagination start  -->
      <div class=""  v-if="fettchDataArray.length > 0">
        <div class="d-flex justify-content-between flex-wrap">
          <nav aria-label="Page navigation">
            <ul class="pagination flex-wrap">
              <!-- Previous button -->
              <!-- <li class="page-item" @click="previousPage">
                <a class="page-link"><i class="tf-icon bx bx-chevron-left"></i></a>
              </li> -->
  
              <!-- Page buttons -->
                <li v-for="(page, index) in paginationData.links" :key="index" class="page-item myPagination m-1" :class="{ active: page.active }">
                  <button class="page-link" @click="goToPage(Number(page.label))" v-html="page.label"></button>
                </li>
              <!-- Next button -->
              <!-- <li class="page-item" @click="nextPage">
                <a class="page-link"><i class="tf-icon bx bx-chevron-right"></i></a>
              </li> -->
            </ul>
          </nav>
          <div class="row">
            <div class="col-12">
              <div class="input-group ">
                <label class="input-group-text" for="inputGroupSelect01">Size</label>
                <select class="form-select" id="inputGroupSelect01" v-model="pageSize" @change="fetchData">
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
          </div>
        </div> 
        <div class="mt-3 ">
          <span class="p-3 bg-light"> Showing: {{ paginationData.from }} - {{ paginationData.to }} of {{ paginationData.total }}</span>
        </div>
      </div>
      <!-- // pagination end -->
      <!-- modal view more -->
      <div class="modal fade" id="viewModal" tabindex="-1" role="dialog" aria-labelledby="modalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modalCenterTitle">Barcode Details </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div v-if="modalloading">
                <div class="d-flex justify-content-center align-items-center">
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div> 
              <div class="row" v-else>
                <div class="col-12">
                  <div class="mb-3 text-center h4"> Code: <span>{{ viewDataArray.barcode }}</span></div>
                  <div class="mb-3 text-center alert-success h5 p-2">  
                    <span  v-if="viewDataArray.status == 0" >Initial ({{ viewDataArray.status }})</span> 
                    <span v-else-if="viewDataArray.status == 1">Printed ({{ viewDataArray.status }})</span>
                    <span v-else-if="viewDataArray.status == 2">Dispatch ({{ viewDataArray.status }})</span>
                    <span v-else-if="viewDataArray.status == 3">Delivered ({{ viewDataArray.status }})</span>
                    <span v-else-if="viewDataArray.status == 4" :class="{'text-success': viewDataArray.status == 4}">Warranty Activated ({{ viewDataArray.status }})</span>
                  </div>
                  <div class="text-center">
                    <p v-show="viewDataArray.product" class="text-wrap"> <strong>Product:</strong> <span>{{ viewDataArray.product?.name }}</span></p>
                    <p> <strong>Created at:</strong> {{formatDateTime (viewDataArray.created_at) }}</p>
                    <p> <strong>Updated at:</strong> {{ formatDateTime (viewDataArray.updated_at)  }}</p>
                    <p v-show="viewDataArray.store_id">Store: {{ viewDataArray.store_id }}</p>
                    <p v-show="viewDataArray.order_id">Order: <span>{{ viewDataArray.order_id }}</span></p>
                  </div>
                  <div class="mb-3 text-center h5 py-3 alert-primary alert" v-show="viewDataArray.customer?.id">
                    <h4>Customer Info</h4>
                    <p v-show="viewDataArray.customer_id" class="text-wrap">Name: <span>{{ viewDataArray.customer?.firstname }} {{ viewDataArray.customer?.lastname }}</span></p>
                    <p v-show="viewDataArray.customer_id" class="text-wrap">Phone: <span>{{ viewDataArray.customer?.phone }}</span></p>
                    <p v-show="viewDataArray.customer_id" class="text-wrap">Email: <span>{{ viewDataArray.customer?.email }} </span></p>
                    <p v-show="viewDataArray.customer_id" class="text-wrap">Address: <span>{{ viewDataArray.customer?.address }}</span></p>
                  </div>
                  <div class="mb-3 text-center h5 py-3 alert-warning alert" v-show="viewDataArray.store?.id">
                    <h4>Store Info</h4>
                    <p v-show="viewDataArray.customer_id" class="text-wrap">Name: <span>{{ viewDataArray.store?.name }}</span></p>
                    <p v-show="viewDataArray.customer_id" class="text-wrap">Phone: <span>{{ viewDataArray.store?.phone }}</span></p>
                    <p v-show="viewDataArray.customer_id" class="text-wrap">Email: <span>{{ viewDataArray.store?.email }} </span></p>
                    <p v-show="viewDataArray.customer_id" class="text-wrap">Address: <span>{{ viewDataArray.store?.address }}</span></p>
                  </div>

                  <div class="mb-3 text-center alert-secondary py-3">
                    <p><strong>Activation Date: </strong> <span>{{ viewDataArray.activation_date }}</span></p>
                    <p class="mb-0"> <strong>Expiry Date: </strong> <span>{{ viewDataArray.expire_date }}</span></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script lang="ts">
  import { defineComponent, ref, nextTick } from 'vue';
  import axios from '../../../../node_modules/axios/index';
  import { useStore } from 'vuex'; // Import useStore from Vuex
  import { DOAMIN_PATH } from "@/api";
  import JsBarcode from 'jsbarcode';
  interface mappingData {
    id: number;
    barcode: string;
    remarks: string;
    status: number;
    product_id: number;
    stock_id: number;
    user_id: number;
    created_at: string;
    updated_at: string;
    store_id: number;
    customer_id: number;
    order_id: number;
    activation_date: string;
    expiry_date: string;
  }
  interface mappingProductData {
  id: number;
    name: string;
    description: string;
    thumb: string | null;
    buy_price: number;
    wholesale_price: number;
    sell_price: number;
    unit: string;
    alert_quantity: number;
    features: string | null;
}
  
  export default defineComponent({
    name: "BarcodeList", // Rename the component to a multi-word name
    data() {
      return {
        fettchDataArray: ref([] as unknown as mappingData[]),
        fettchDataProductArray: ref([] as unknown as mappingProductData[]),
        status: '',
        paginationData: {
        links: [
          {
            label: '',
            url: '',
            active: '',
          }
        ],
        prev_page: 0,
        next_page: 0,
        total: 0,
        per_page: 0,
        current_page: 0,
        last_page: 0,
        from: 0,
        to: 0,
        successReport: false
      },
        pageSize: 20,
        loading: false,
        modalloading: false,
        successReport: false,
        currentPage: 1,
        token: localStorage.getItem('token'),
        barcodeRefs: {} as { [key: number]: SVGSVGElement | null },
        barcodeRefs2: {} as { [key: number]: SVGSVGElement | null },
        searchKey: '',
        fromDate: '',
        toDate: '',
        productId: '',
        viewDataArray:{
          id: 0,
          product_id: 0,
          stock_id: 0,
          user_id: 0,
          status: 0,
          barcode: '',
          remarks: '',
          activation_date: '',
          expire_date: '',
          order_id: 0,  
          store_id: 0,
          customer_id: 0,
          created_at: '',
          updated_at: '',
            product: {
              id: 4,
              name: '',
              description: '',
              thumb: '',
              buy_price: 0,
              wholesale_price: 0,
              sell_price: 0,
              unit: '',
              alert_quantity: 10,
              features: null,
              user_id: null,
              created_at: '',
              updated_at: '',
          },
          user: {
              id: 1,
              email: '',
              email_verified_at: '',
              phone: '',
              firstname: '',
              lastname: '',	
              profile_img: '',
              national_id_no: '',
              address: '',
              type: '',
              created_at: '',
              updated_at: '',
          },
          store: {
            id: 0,
            name: '',
            phone: '',
            email: '',
            address: '',
          },
          customer: {
            id: 0,
            email: '',
            email_verified_at: '',
            phone: '',
            firstname: '',
            lastname: '',
            profile_img: '',
            national_id_no: '',
            address: '',
            type: '',
            created_at: '',
            updated_at: '',
        },
        },
        showDropdown: [] as boolean[],
        dropdownItems: ['Regenerate', 'Delete'],
      };
    },
    methods: {
      formatDateTime(isoString: string | number | Date) {
      const date = new Date(isoString);

      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        // timeZoneName: 'short',
      };

      return date.toLocaleString(undefined, options);
    },
      async fetchData() {
        try {
          this.loading = true;
          const response = await (axios as any).get(DOAMIN_PATH + 'barcode',
            {
              headers: {
                Authorization: `Bearer ${this.token}`,
              },
              params: {
                page: this.currentPage,
                size: this.pageSize,
              },
            }
          );
          if (response.status == 200) {
            this.fettchDataArray = response.data.data;
            this.paginationData = response.data;
            this.loading = false;
            nextTick(() => {
            this.generateBarcodes();
            });
          }
        } catch (error) {
          console.error(error);
        }
    },
      async fetchDataProuct() {
        try {
          const response = await (axios as any).get(DOAMIN_PATH + 'product',
            {
              headers: {
                Authorization: `Bearer ${this.token}`,
              },
              params: {
                page: this.currentPage,
                size: 5000,
              },
            }
          );
          if (response.status == 200) {
            this.fettchDataProductArray = response.data.data;
          }
        } catch (error) {
          console.error(error);
        }
    },
      goToPage(pageOrLabel: string | number | null) {
          
        if (pageOrLabel === "Next »" || pageOrLabel === "&laquo; Previous") {
          console.log('Skipping Next/Previous');
          return;
        }
        if (typeof pageOrLabel === 'number') {
          console.log('Setting currentPage  :', pageOrLabel);
          this.currentPage = pageOrLabel;
          console.log('curentPage:', this.currentPage);
          // this.fetchAllData();
        } else if (pageOrLabel) {
          const match = pageOrLabel.match(/page=(\d+)/);
          if (match) {
            console.log('else id match:', parseInt(match[1]));
            this.currentPage = parseInt(match[1]);
          }
        }
        this.fetchData();
      },

      // // Add methods to navigate to the previous and next pages
      previousPage() {
        if (this.paginationData.current_page > 1) {
          this.currentPage = this.paginationData.current_page - 1;
          this.fetchData();
        }
      },

      nextPage() {
        if (this.paginationData.current_page < this.paginationData.last_page) {
          this.currentPage = this.paginationData.current_page + 1;
          this.fetchData();
        }
      },
      async handleSubmit() {
      try {
          const response = await (axios as any).post(DOAMIN_PATH + 'stock',
            {
              headers: {
                Authorization: `Bearer ${this.token}`,
              }
            }
          );
          if (response.status == 201) {
            const closeButton = document.getElementById('close');
            if (closeButton !== null) {
              closeButton.click();
            }
            this.successReport = true;
            this.fetchData();
            setTimeout(() => { this.successReport = false;}, 3000);
          }
      } catch (error) {
        console.error(error);
      }
    },
     async generateBarcodes() {
      this.fettchDataArray.forEach((dataItem) => {
        const svgElement = this.barcodeRefs[dataItem.id];
        const svgElement2 = this.barcodeRefs2[dataItem.id];
        if (svgElement && dataItem.barcode && svgElement2 && dataItem.barcode) {
          // Explicitly cast to SVGSVGElement
          JsBarcode(svgElement as SVGSVGElement, dataItem.barcode, {
            format: 'CODE128',
            lineColor: '#000',
            width: 1,
            height: 30,
            displayValue: true,
          });
          JsBarcode(svgElement2 as SVGSVGElement, dataItem.barcode, {
            format: 'CODE128',
            lineColor: '#000',
            width: 1,
            height: 30,
            displayValue: true,
          });
          console.log('_svgElement', svgElement);
          console.log('_svgElement', svgElement2);
          this.$refs[`barcode-${dataItem.id}`]
        }
      });
    },
    async searchData() {
      try {
        this.loading = true;
        const response = await (axios as any).get(DOAMIN_PATH + 'barcode',
          {
            params: {
              search_key: this.searchKey || '',
              status: this.status || '',
              product_id: this.productId || '',
              from_date: this.fromDate || '',
              to_date: this.toDate || '',
            },
            headers: {
              Authorization: `Bearer ${this.token}`,
            }
          }
        );
          if (response.status == 200) {
            this.fettchDataArray = response.data.data;
            this.paginationData = response.data;
            this.loading = false;
            nextTick(() => {
              this.generateBarcodes();
            });
          }
        } catch (error) {
          console.error(error);
        }
    },
    async viewData(id: any) {
      try {
      this.modalloading = true;
      const response = await (axios as any).get(DOAMIN_PATH + 'barcode/'+id, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });
      if (response.status == 200) {
        this.viewDataArray = response.data.data;
        this.modalloading = false;
      }
      } catch (error) {
        console.error(error);
      }
    },
    toggleDropdown(index: number) {
      this.showDropdown[index] = !this.showDropdown[index]
    },
    handleClick(item: any) {
      console.log(`You clicked on ${item}`)
      // Add your logic here
    },
    },
    mounted() {
      this.fetchData(); 
      this.fetchDataProuct();
    },
  });
  </script>


