<template >
  <div class="">
    <!-- <TopBarView /> -->
    <div class="content-wrapper d-flex align-items-center" style="min-height: 100vh; width: 100%">
      <!-- <Search /> -->
      <!-- Content -->
      <div class="w-100">
        <div>
          <div class="flex-grow-1 container-p-y px-4">
            <div class="col-md-9 col-sm-12 mx-auto">
              <div class="row h-100">
                <div class="col-md-6 mx-auto">
                  <div class="h-100">
                    <div class="d-flex align-items-center justify-content-center h-100">
                      <div class="w-100">
                        <div class="authentication-wrapper authentication-basic container-p-y">
                          <div class="authentication-inner w-100">
                            <!-- login -->
                            <div class="card">
                              <div class="card-body">
                                <!-- Logo -->
                                <div class="app-brand justify-content-center">
                                  <a href="/" class="app-brand-link gap-2">
                                    <span class="app-brand-text demo text-body fw-bold text-uppercase mb-3"> 
                                      <img src="https://pos-file.phicsart.com/profile_user.png" alt="n/a" class="img-fluid" style="width:80px; height:80px;">
                                    </span>
                                  </a>
                                </div>
                                <!-- /Logo -->
                                <span>{{ cardData }}</span>
                                <h4 class="mb-4 text-center">Raida Trade International &mdash; Customer Info 👋</h4>
                                <div v-if="errorMessages.message" class="alert alert-danger alert-dismissible" role="alert">
                                  {{ errorMessages.message }}
                                  <button type="button" class="btn-close" aria-label="Close"></button>
                                </div>
                                <div v-if="successReport" class="alert alert-success alert-dismissible" role="alert">
                                  Successfully
                                  <button type="button" class="btn-close"  aria-label="Close"></button>
                                </div>
                                <form id="formAuthentication" class="mb-3" @submit.prevent="handleSubmit()">
                                  <div class="mb-3">
                                    <label for="phone" class="form-label">Phone Number <sup class="text-danger">*</sup> </label> <span v-if="invalidPhone" class="text-danger">Phone number must be 11 digits</span>
                                     <input type="text" class="form-control" id="phone" name="phone" placeholder="Enter your Phone " @change="checkUser" v-model="formData.phone" required>
                                  </div>
                                  <div class="mb-3">
                                    <label for="firstname" class="form-label">Name <sup class="text-danger">*</sup></label> 
                                     <input type="text" class="form-control" id="firstname" name="firstname" placeholder="Enter your Name" v-model="formData.firstname">
                                  </div>
                                  <div class="mb-3">
                                    <label for="email" class="form-label">Email </label> 
                                     <input type="Email" class="form-control" id="email" name="email" placeholder="Enter your Email" v-model="formData.email" >
                                  </div>
                                  <div class="mb-3">
                                      <label
                                        class="form-label"
                                        for="store"
                                        >Store
                                      </label>                                
                                        <SeLect2 v-model="formData.store_id" required :placeholder="'Select Store'" :options="fetchCategoryList" class="w-100 my-select" @change="myChangeEvent" @select="mySelectEvent" :multiple="false" aria-required="true"  />
                                  </div>
                                  <div class="mb-3">
                                    <label for="address" class="form-label">Address </label> 
                                     <textarea class="form-control" placeholder="Enter your Address ..." rows="3" id="address" name="address" v-model="formData.address"></textarea>
                                  </div>
                                  <div class="mb-3">
                                    <div class="row">
                                      <div class="col-12">
                                        <button type="submit" class="btn btn-primary w-100 justify-content-center btn-lg" :disabled="submitBtn">
                                          <span v-if="!submitBtn">Active Warranty</span> 
                                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="submitBtn"></span>
                                          <span class="visually-show" v-if="submitBtn"> Saving ....</span>
                                        </button>   
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>  
          </div>
        </div>
      </div>
      <!-- <Footer /> -->
      <!-- / Footer -->

      <div class="content-backdrop fade"></div>
    </div>
    <!-- / Layout wrapper -->
  </div>
</template>

<script lang="ts">
interface DataArray {
  id: number;
  store_id: number,
  email: '',
  name: '',
  phone: '',
  division: '',
  district: '',
  trade_license: '',
  address: '',
  user: {
    id: number;
    name: '',
    phone: '',
    address: '',
    email: '',
    email_verified_at: '',
    created_at: '',
    updated_at: '',
  },
  is_default: number;
  user_id: '', // Assuming this property exists
}

import { defineComponent, ref } from "vue";
import axios from "axios";
import { DOAMIN_PATH } from "@/api";
import { useStore } from "vuex";
import store from "@/store";
// import Footer from "../FooterView.vue";
// import TopBarView from "../TopBarView.vue";
// import { useStore } from "vuex";
export default defineComponent({
  // components: { Footer, TopBarView},
  name: "cutomer-form",
  props: ['cardData'],
  data() {
    // const store = useStore();
    // let userData = store.state.auth.userData || localStorage.getItem('userData');
    // userData = JSON.parse(userData);
    const store = useStore();
    const token = store.state.auth.token || localStorage.getItem('token');
    return {
      errorMessages: ref({ message: '' }),
      fetchCategoryList: [{
           id: Number,
           text: '',
       }],
      submitBtn: ref(false),
      token,
      successReport: false,
      invalidPhone: false,
      is_user: false,
      loading: false,
      dataArray: ref([] as DataArray[]), 
      formData: {
        id: null,
        phone: '',
        firstname: '',
        email: '',
        address: '',
        store_id: 0,
        type: 'CUSTOMER'
      },
    };
  },
  watch: {

  },
  methods: {
    async checkUser() {
      if (this.formData.phone.length != 11) {
        this.invalidPhone = true;
      }
      try {
        const response = await (axios as any).get( DOAMIN_PATH + 'user-by-phone/' + this.formData.phone);
        if (response.status == 200) {
          this.invalidPhone = false;
          this.formData = response.data.data;
          this.is_user = true;
          // this.activeWarranty();
          console.log('user found', this.formData);
        }
      }catch (error) {
        console.error(error);
      }
    },

    async handleSubmit() {
      // save user
      if(this.is_user == false) {
        this.submitBtn = true;
        //convert formData store_id to number
        this.formData.store_id = Number(this.formData.store_id);
        console.log( 'formData', this.formData);
        try {
            const response = await (axios as any).post( DOAMIN_PATH + 'customer-create', this.formData);
            if(response.status == 201) {
              console.log('user created', response.data.data);
              setTimeout(() => { this.successReport = false; }, 3000);
              const response2 = await (axios as any).get( DOAMIN_PATH + 'barcode-status-update-warranty/' + localStorage.getItem('cardData'), {
                params: {
                  status: 4,
                  store_id: this.formData.store_id,
                  customer_id: response.data.data.id,
                }
              });
              console.log( '__statusUpdate__', response2);
              if(response2.status == 200) {
                localStorage.setItem('barcodeData', JSON.stringify(response2.data.data));
                this.$emit( 'update-status');
                this.submitBtn = false;
                this.successReport = true;
              }
            }
        }
        catch (error) {
          console.error(error);
          this.submitBtn = false;
        }
      }
      // console.log('__statusUpdate__', this.data);
      //update barcode status
      else{
        const response = await (axios as any).get( DOAMIN_PATH + 'barcode-status-update-warranty/' + localStorage.getItem('cardData'), {
          params: {
            status: 4,
            store_id: this.formData.store_id,
            customer_id: this.formData.id,
          }
        });
        if(response.status == 200) {
          localStorage.setItem('barcodeData', JSON.stringify(response.data.data));
          this.$emit( 'update-status');
          this.submitBtn = false;
          this.successReport = true;
        }
        console.log( '__statusUpdate__', response);
        this.$emit( 'update-status');
        this.submitBtn = false;
      }
      
    },
    async fatchStoreData(){
      try {
        this.loading = true;
        const response = await (axios as any).get(DOAMIN_PATH + 'store', { 
          params: {
            page: 1,
            size: 5000
          } 
        });
        if (response.status === 200) {
          this.dataArray = response.data.data;
          this.fetchCategoryList = response.data.data.map((store: { id: any; name: any; }) => ({
            id: store.id,
            text: store.name
          }));  
          this.loading = false;
        }
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    myChangeEvent(val: any){
      console.log(val);
    },
    mySelectEvent({id, text}: any) {
      console.log({id, text})
      this.formData.store_id = id
      console.log(this.formData.store_id)
    }
   
  },

  mounted() {
    // Set isLoading to true when the component is mounted
    this.fatchStoreData();
  }
});
</script>