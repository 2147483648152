<template >
  <div class="layout-page d-flex align-items-center" style="min-height: 100vh; width: 100%;">
    <!-- <TopBarView /> -->
    <div class="content-wrapper">
      <!-- <Search /> -->
      <!-- Content -->
      <div>
        <div>
          <div class="flex-grow-1 container-p-y px-4">
            <div class="col-md-9 col-sm-12 mx-auto">
              <div class="row h-100">
                <div class="col-md-6 mx-auto">
                  <div class="h-100">
                    <div class="d-flex align-items-center justify-content-center h-100">
                      <div class="w-100">
                        <div class="authentication-wrapper authentication-basic container-p-y">
                          <div class="authentication-inner w-100">
                            <!-- login -->
                            <div class="card">
                              <div class="card-body">
                                <!-- Logo -->
                                <div class="app-brand justify-content-center">
                                  <a href="/" class="app-brand-link gap-2">
                                    <span class="app-brand-text demo text-body fw-bold text-uppercase mb-3"> 
                                      <img src="https://pos-file.phicsart.com/profile_user.png" alt="n/a" class="img-fluid" style="width:80px; height:80px;">
                                    </span>
                                  </a>
                                </div>
                                <!-- /Logo -->
                                <h4 class="mb-4 text-center">Raida Trade International &mdash; Code Varify 👋</h4>
                                
                                <form id="formVarify" class="mb-3" @submit.prevent="handleSubmit()">
                                  <div class="mb-3">
                                    <label for="firstname" class="form-label">Scan Barcode <sup class="text-danger">*</sup></label>
                                    <input type="text" class="form-control" id="code" name="code" v-model="formData.code" placeholder="Code!">
                                  </div>
                                  <div class="mb-3">
                                    <div class="row">
                                      <div class="col-12">
                                        <button type="submit" class="btn btn-primary btn-lg w-100 justify-content-center" :disabled="submitBtn">
                                          <span v-if="!submitBtn">Varify Code</span> 
                                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="submitBtn"></span>
                                          <span class="visually-show" v-if="submitBtn"> Varifying ....</span>
                                        </button>   
                                      </div>
                                    </div>
                                  </div>
                                </form>
                                <div v-if="errorMessages" class="alert alert-warning text-center alert-dismissible py-3 display-6" role="alert">
                                   Opps, Code Invalid!
                                </div>
                                <div v-if="successReport" class="alert alert-success alert-dismissible text-center py-3 display-6" role="alert">
                                  Code Varified
                                  <button type="button" class="btn-close"  aria-label="Close"></button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>  
          </div>
        </div>
      </div>
      <!-- <Footer /> -->
      <!-- / Footer -->

      <div class="content-backdrop fade"></div>
    </div>
    <!-- / Layout wrapper -->
  </div>
</template>

<script lang="ts">
import { DOAMIN_PATH } from "@/api";
import axios from "axios";
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
// import Footer from "../FooterView.vue";
// import TopBarView from "../TopBarView.vue";
// import { useStore } from "vuex";
export default defineComponent( { 
  // components: { Footer, TopBarView},
  name: "varify-form",
  data() {
    const store = useStore();
    let cardData = localStorage.getItem('cardData');
    // userData = JSON.parse(userData);
    return {
      errorMessages: ref(false),
      submitBtn: ref(false),
      successReport: ref(false),
      formData: { code: '' },
      cardData,
    };
  },
  watch: {

  },
  methods: {
    async handleSubmit() {
      try {
        const response = await (axios as any).get(DOAMIN_PATH + 'barcode-by-code/' + this.formData.code);
          if(response.status == 200) {
            this.successReport = true;
            if(parseInt(response.data.data.status) == 0) {
              setTimeout(() => { this.successReport = false; }, 3000);
              this.$emit( 'update-link');
              localStorage.setItem('cardData', response.data.data.id); 
              console.log('cardData', localStorage.getItem('cardData'));
            }
            else if (response.data.data.status == 4) {
              this.$emit( 'update-status');
              localStorage.setItem('cardData', response.data.data.id); 
              localStorage.setItem('barcodeData', JSON.stringify(response.data.data));
              setTimeout(() => { this.errorMessages = false; }, 3000);
            }
          }
        }catch (error) {
          console.error(error);
          this.errorMessages = true;
          setTimeout(() => { this.errorMessages = false; }, 3000);
      }
    },
  },

  mounted() {
    // Set isLoading to true when the component is mounted
    console.log('_propsLink_');
  }
});
</script>