<template>
  <div>
    <!-- search form start  -->
    <div class="card mb-3">
      <div class="card-body">    
        <div class="row">
          <div class="input-group">
            <div class="input-group-prepend">
              <!-- <select class="form-select" aria-label="Default select example">
                    <option selected>Search By-</option>
                    <option value="1">Category</option>
                    <option value="2">Product Name </option>
              </select>   -->
            </div>
            <span class="input-group-text" id="basic-addon1">From</span>
            <input type="date" class="form-control" placeholder="Enter Search Key !" v-model="fromDate">
            <span class="input-group-text" id="basic-addon1">To</span>
            <input type="date" class="form-control" placeholder="Enter Search Key !" v-model="toDate">
            <input type="text" class="form-control w-25" placeholder="Enter Search Key !" v-model="searchKey">
            <button type="button" class="btn btn-primary me-1 ms-1"><font-awesome-icon :icon="['fas', 'magnifying-glass']" @click="searchData"/></button>
            <button type="button" class="btn btn-default border"><font-awesome-icon :icon="['fas', 'broom']" @click="clearInterval()" /></button>            
          </div>
        </div>
      </div>
    </div>
    <!-- search form end  -->
    <div v-if="successReport" class="alert alert-success alert-dismissible" role="alert">
       Payment Successfully Updated !
     <button type="button" class="btn-close"  aria-label="Close"></button>
   </div>
    <!-- table start  -->
    <div class="card mb-1 d-none d-md-block">
      <div class="card-body">
        <div class="d-flex justify-content-center align-items-center" v-if="loading">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="table-responsive" v-else>
          <table class="table card-table" v-if="fettchDataArray.length > 0" >
            <thead>
              <tr>
                <th>Id</th>
                <!-- <th v-if="userData?.type=='SUPERADMIN'">Company</th> -->
                <th>Invoice No</th>
                <th>Customer Type</th>
                <th>Total | Due | Payment</th>
                <th>Date adfasdf </th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(dataItem, index) in fettchDataArray" :key="dataItem.id">
                <td>{{ index + 1 }}</td>
                <!-- <td v-if="userData?.type=='SUPERADMIN'">{{ dataItem.store?.name }}</td> -->
                <td>{{ dataItem.invoice_no }}</td>
                <td>{{ dataItem.customer_type }}</td>
                <td>{{ dataItem.total }} |  <span class="badge bg-danger" v-if="dataItem.due < 0">{{ dataItem.due }}</span> <span v-else>{{ dataItem.due }}</span> | {{ dataItem.payment}}</td>
                <!-- <td>D{{ dataItem.discount }} SB-{{ dataItem.sub_total }} P {{ dataItem.payment}}</td> -->
                <td>{{ dataItem.created_at }}</td>
                <td>
                  <button type="button" class="me-2 btn btn-sm btn-info" @click="editItem(dataItem.id.toString())">
                    <font-awesome-icon :icon="['fas', 'eye']" />
                  </button>
                  <button type="button" v-show="dataItem.due < 0" class="me-2 btn btn-sm btn-warning" data-bs-toggle="modal" data-bs-target="#modalCenter" @click="paymentEdit(dataItem)">
                    Due Pay
                  </button>
                  <!-- <button type="button" class="me-2 btn btn-sm btn-danger" 
                    data-bs-toggle="modal" data-bs-target="#modalCenter"
                    @click="fetchItem(dataItem)"
                  >
                    <font-awesome-icon :icon="['fas', 'trash-can']" />
                  </button> -->
                </td>
              </tr>
            </tbody>
          </table>
          <div v-else>
            <h5 class="text-center mb-0">Please Sale Some Product</h5>
          </div>
        </div>
      </div> 
    </div>
    <!-- // table end -->
    <div class="mobile-view d-block d-md-none">
      <div class=" card" v-if="loading">
        <div class="card-body">
          <div class="d-flex justify-content-center align-items-center" >
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
      <div  v-if="fettchDataArray.length > 0">
        <div class="card mb-4" v-for="dataItem in fettchDataArray" :key="dataItem.id">
          <div class="card-body">
          <!-- <span>{{index+1}}</span><br> -->
          
          <strong> Name: {{ dataItem.invoice_no }}</strong><br/>
          <small>Cutomer Type: {{ dataItem.customer_type }}</small> <br/>
          <small>Total: {{ dataItem.total }}</small> <br/>
          <small>Payment: {{ dataItem.payment }}</small> <br/>
          <small>Due: {{ dataItem.due }}</small> <br/>
          <small>Date: {{ dataItem.created_at }}</small> <br/>
          <button type="button" class="me-2 btn btn-sm btn-info" @click="editItem(dataItem.id.toString())">
            <font-awesome-icon :icon="['fas', 'eye']" />
          </button>
          <button type="button" v-show="dataItem.due > 0" class="me-2 btn btn-sm btn-warning" data-bs-toggle="modal" data-bs-target="#modalCenter" @click="paymentEdit(dataItem)">
            Due Pay
          </button>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="card">
          <div class="card-body">
            <h5 class="text-center mb-0">Please Sale Some Product</h5>
          </div>
        </div>
      </div>

    </div>
    <!-- pagination start  -->
    <div class="mt-3" v-if="fettchDataArray.length > 0">
      <div class=" d-flex justify-content-between flex-wrap">
        <nav aria-label="Page navigation">
          <ul class="pagination ">
            <!-- Previous button -->
            <!-- <li class="page-item" @click="previousPage">
              <a class="page-link"><i class="tf-icon bx bx-chevron-left"></i></a>
            </li> -->

            <!-- Page buttons -->
              <li v-for="(page, index) in paginationData.links" :key="index" class="page-item myPagination" :class="{ active: page.active }">
                <button class="page-link" @click="goToPage(Number(page.label))" v-html="page.label"></button>
              </li>
            <!-- Next button -->
            <!-- <li class="page-item" @click="nextPage">
              <a class="page-link"><i class="tf-icon bx bx-chevron-right"></i></a>
            </li> -->
          </ul>
        </nav>
        <div class="row">
          <div class="col-12">
            <div class="input-group ">
              <label class="input-group-text" for="inputGroupSelect01">Size</label>
              <select class="form-select" id="inputGroupSelect01" v-model="pageSize" @change="fetchData">
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
        </div>
      </div> 
      <div class="mt-3">
          <span class="p-3 bg-light"> Showing: {{ paginationData.from }} - {{ paginationData.to }} of {{ paginationData.total }}</span>
        </div>
    </div>
    <!-- // pagination end -->
    <!-- Delete Confirm Modal -->
    <div class="modal fade" id="modalCenter" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <form action="" @submit.prevent="editOrder(editPayment.id)">
            <div class="modal-header ">
              <h5 class="modal-title text-center" id="modalCenterTitle">Due Payment</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body text-center">  
              <div class="mb-3">
                <label class="form-label fs-6">Pay Due Amount</label>
                <span class="bg-light p-2">{{ Math.abs(editPayment.due_total) }}</span> 
                <input type="text" class="form-control" :max="Math.abs(editPayment.due_total)" min="0" v-model="editPayment.payment" >
              </div>
            </div>
            <div class="modal-footer text-center justify-content-center">
              <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" id="modalCenterClose">
                Close
              </button>
              <button type="submit" class="btn btn-outline-primary" :disabled="submitBtn">
                <span v-if="!submitBtn">Confirm</span> 
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="submitBtn"></span>
                <span class="visually-show" v-if="submitBtn"> Saving ...</span>
              </button>
            </div>
         </form>
        </div>
      </div>
    </div>
    <!-- modal end  -->
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue';
import axios from '../../../../node_modules/axios/index';
import { useStore } from 'vuex'; // Import useStore from Vuex
import { DOAMIN_PATH } from "@/api";
interface mappingData {
  id: number,
  invoice_no: string,
  customer_type : string,
  total: string,
  discount: number,
  sub_total: number,
  payment: number,
  due: number,
  created_at: string,
  order_items: [{
      id: number,
      buy_price: '',
      sell_price: "",
      quantity: "",
      discount: "",
      total: "",
      order_id: "",
      product_id: "",
      customer_id: "",
      user_id: "",
      store_id: "",
  }],
  user: {
    id: number,
    type: string,
    firstname: string,
    lastname: string
  }
}
export default defineComponent({
  name: "CategoryList", // Rename the component to a multi-word name
  data() {
    const store = useStore();
    const token = store.state.auth.token || localStorage.getItem('token');
    const userId = store.state.auth.userId || localStorage.getItem('userId');

    return {      
      store,
      token,
      userId,
      successReport: false,
      submitBtn: false,
      fettchDataArray: ref([] as mappingData[]), // Explicitly define the type as Post[] // Explicitly define the type as Post[]

      deletevalue: {
        id: 0,
        name: "",
      },
      isDataTrueList: true,
      id: '',
      loading: false,
      pageSize: 10, // set a default value
      currentPage: 1, // set a default value
      paginationData: {
        links: [
          {
            label: '',
            url: '',
            active: '',
          }
        ],
        prev_page: 0,
        from: 0,
        to: 0,
        next_page: 0,
        total: 0,
        per_page: 0,
        current_page: 0,
        last_page: 0,

      }, // Object to store pagination data
      editPayment:{
        id: 0,
        payment: 0,
        due_total: 0,
        due: 0,
        customer_type: "",
      },
      paymentForm: {
        payment_type: "DUE",
        payment_method: "Cash",
        customer_type: "",
        bill_total: Number(0),
        payment: Number(0),
        due: Number(0),
        order_id: Number(0),
        customer_id: Number(0),
        user_id: Number(0),
        store_id: Number(0),
        created_at: "",
      },
      searchKey: '',
      fromDate: '',
      toDate: '',
    };
  },
  methods: {
    async fetchAllData() {
      try {
        this.loading = true;
        if (!this.token) {
            throw new Error('Token not found');
          }
          const response = await axios.get(DOAMIN_PATH + 'order',
          {
            params: {
              page: this.currentPage,
              size: this.pageSize,
            },
            headers: {
              Authorization: `Bearer ${this.token}`,
              UserId: this.userId,
            }
          }
          );
        if (response.status === 200) {
          this.fettchDataArray = response.data.data.map((item: mappingData) => ({
            ...item,
            created_at: new Date(item.created_at).toLocaleDateString(),
          }));
          this.paginationData = response.data;
          this.loading = false;
        }
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },

    // Delete areas data
    async fetchItem(item: any) {
      this.deletevalue = item;
      console.log("deletevalue", this.deletevalue);
    },
    async editOrder(id: number) {
      const paymentAmount = Number(this.editPayment.payment);
      const formattedPaymentAmount = Number(paymentAmount.toFixed(2));

      if (!isNaN(paymentAmount) && Math.abs(this.editPayment.due_total) >= formattedPaymentAmount) {
        this.editPayment.due = Math.abs(this.editPayment.due_total) - formattedPaymentAmount;
        this.editPayment.due = -this.editPayment.due;
        this.editPayment.payment = formattedPaymentAmount;
        this.paymentForm.payment = formattedPaymentAmount;
        this.paymentForm.due = this.editPayment.due;
        console.log('editOrder', this.editPayment);
        console.log('paymentForm', this.paymentForm);
        this.submitBtn = true;
        try {
          const response = await (axios as any).put(DOAMIN_PATH + 'order/'+id, this.editPayment, {
              headers: {
                Authorization: `Bearer ${this.token}`,
                UserId: this.userId,
              },
            });
          if (response.status == 200) {
            console.log('_updated_successfully', response);
            const payementresponse = await (axios as any).post(DOAMIN_PATH + 'payment', this.paymentForm, {
                headers: {
                  Authorization: `Bearer ${this.token}`,
                  UserId: this.userId,
                },
              });
              console.log('payementresponse', payementresponse);
              if (payementresponse.status == 201) {
                console.log('_updated_successfully_payementresponse', payementresponse);
                document.getElementById("modalCenterClose")?.click();
                this.successReport = true;
                setTimeout(() => { this.successReport = false; }, 4000);
                this.fetchAllData();
                this.submitBtn = false;
              }
          }
        } catch (error) {
          console.error(error);
        }
      }
      else{
        console.log('Please Enter Valid Amount');
      }
    },
    async paymentEdit(item: any) {
     this.editPayment.id = item.id;
      this.editPayment.due_total = item.due;
      this.editPayment.customer_type = item.customer_type;
      this.paymentForm.order_id = item.id;
      this.paymentForm.bill_total = Math.abs(item.due);
      this.paymentForm.customer_type = item.customer_type;
      this.paymentForm.customer_id = item.customer_id;
      this.paymentForm.store_id = item.store_id;
      this.paymentForm.user_id = item.user_id;      
    },
    editItem(id: string) {
      // Navigate to the edit route with the ID parameter
      // this.$router.push({ params: { id } });
      this.$router.push({ path: `invoice/${id}` });
    },
    async searchData() {
      try {
        this.loading = true;
        if (!this.token) {
            throw new Error('Token not found');
          }
          const response = await axios.get(DOAMIN_PATH + 'order',
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
              UserId: this.userId,
            },
            params: {
              search_key: this.searchKey,
              from_date: this.fromDate,
              to_date: this.toDate,
            }
          }
          );
        if (response.status === 200) {
          this.fettchDataArray = response.data.data.map((item: mappingData) => ({
            ...item,
            created_at: new Date(item.created_at).toLocaleDateString(),
          }));
          this.paginationData = response.data;
          this.loading = false;
        }
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    async clearInterval() {
      this.searchKey = '';
      this.fromDate = '';
      this.toDate = '';
      this.fetchAllData();
    },
    // pagination function
    async fetchData() {
    // Optionally, you can reset the current page to 1 when the page size changes
    // this.currentPage = 1;
    await this.fetchAllData();
    },
    goToPage(pageOrLabel: string | number | null) {
      
    if (pageOrLabel === "Next »" || pageOrLabel === "&laquo; Previous") {
      console.log('Skipping Next/Previous');
      return;
    }
    if (typeof pageOrLabel === 'number') {
      console.log('Setting currentPage  :', pageOrLabel);
      this.currentPage = pageOrLabel;
      console.log('curentPage:', this.currentPage);
      this.fetchAllData();
    } else if (pageOrLabel) {
      const match = pageOrLabel.match(/page=(\d+)/);
      if (match) {
        console.log('else id match:', parseInt(match[1]));
        this.currentPage = parseInt(match[1]);
      }
    }

    this.fetchAllData();
    },


    // Add methods to navigate to the previous and next pages
    previousPage() {
      if (this.paginationData.current_page > 1) {
        this.currentPage = this.paginationData.current_page - 1;
        this.fetchAllData();
      }
    },
    nextPage() {
      if (this.paginationData.current_page < this.paginationData.last_page) {
        this.currentPage = this.paginationData.current_page + 1;
        this.fetchAllData();
      }
    },
    
  },
  mounted() {
    this.fetchAllData();
  },
});
</script>