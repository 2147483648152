<template>
    <!-- Layout container -->
    <div class="layout-page">
    <!-- Navbar -->
    <Topbar />
    <!-- / Navbar -->
    <!-- Content wrapper -->
    <div class="content-wrapper">
      <!-- Content -->
      <div class="px-4 flex-grow-1 container-p-y">
        <div class="row">
          <div class="col-md-7">
            <div class="card mb-4">
              <div class="d-flex align-items-end row">
                <div class="col-sm-7">
                  <div class="card-body">
                    <h3 class="card-title mb-2"><span class="text-primary"> {{userData.firstname}} {{userData.lastname}} </span></h3>
                    <p class="mb-0">{{userData.email}}</p>
                    <p class="mb-0"> {{userData.phone}}</p>
                    <p class="mb-0">{{ userData.address }}</p>
                  </div>
                </div>
                <div class="col-sm-5 text-center text-sm-left">
                  <div class="card-body pb-0 px-0 px-md-4">
                    <img
                      src="/assets/img/illustrations/man-with-laptop-light.png"
                      height="140"
                      alt="View Badge User"
                      data-app-dark-img="illustrations/man-with-laptop-dark.png"
                      data-app-light-img="illustrations/man-with-laptop-light.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-5">
            <div class="card mb-4">
              <div class="d-flex align-items-end row">
                <div class="col-sm-8">
                  <div class="card-body">
                    <h3 class="card-title mb-2"> <span class="text-primary">Help & Support</span></h3> <strong>Developed by &mdash;</strong>
                    <a href="http://phicsart.com" target="_blank" rel="noopener noreferrer"> PhicsArt</a> <br>
                    <strong>Contact &mdash;</strong>
                    <a href="https://wa.me/+8801618180022" target="_blank" rel="noopener noreferrer">Whatsapp (+880 1618 180022)</a><br>
                    <a href="tel:+8801618180022" target="_blank" rel="noopener noreferrer">Call (+880 1618 180022)</a>
                  </div>
                </div>
                <div class="col-sm-4 text-center text-sm-left">
                  <div class="card-body pb-0 px-0 px-md-4">
                    <img
                      src="https://phicsart.com/uploads/images/top-page/menu/logo.svg"
                      height="140"
                      alt="View Badge User"
                      data-app-dark-img="illustrations/man-with-laptop-dark.png"
                      data-app-light-img="illustrations/man-with-laptop-light.png"
                      class="img-fluid"
                      style="height: 140px;"
                      />
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          <!-- total card -->
          <div class="col-lg-2 col-md-2 col-6 mb-4">
            <div class="card ">
              <div class="card-body">
                <div class="d-flex justify-content-center align-items-center" v-if="loading">
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                <div v-else>
                  <div
                    class="card-title d-flex align-items-start justify-content-between" 
                  >
                    <div class="avatar flex-shrink-0">
                      <img
                        src="/assets/img/icons/unicons/wallet-info.png"
                        alt="Credit Card"
                        class="rounded"
                      />
                    </div>
                  </div>
                  <span>Total Card</span>
                  <h3 class="card-title text-nowrap mb-1">{{ fettchDataArray.no_of_card }}</h3>
                  <!-- <small class="text-success fw-semibold"><i class="bx bx-up-arrow-alt"></i> +28.42%</small> -->
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-6 mb-4">
            <div class="card ">
              <div class="card-body">
                <div class="d-flex justify-content-center align-items-center" v-if="loading">
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                <div v-else>
                  <div
                    class="card-title d-flex align-items-start justify-content-between" 
                  >
                    <div class="avatar flex-shrink-0">
                      <img
                        src="/assets/img/icons/unicons/wallet-info.png"
                        alt="Credit Card"
                        class="rounded"
                      />
                    </div>
                  </div>
                  <span>Initial Card</span>
                  <h3 class="card-title text-nowrap mb-1">{{ fettchDataArray.no_of_card_0 }}</h3>
                  <!-- <small class="text-success fw-semibold"><i class="bx bx-up-arrow-alt"></i> +28.42%</small> -->
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-6 mb-4 d-none">
            <div class="card ">
              <div class="card-body">
                <div class="d-flex justify-content-center align-items-center" v-if="loading">
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                <div v-else>
                  <div
                    class="card-title d-flex align-items-start justify-content-between" 
                  >
                    <div class="avatar flex-shrink-0">
                      <img
                        src="/assets/img/icons/unicons/wallet-info.png"
                        alt="Credit Card"
                        class="rounded"
                      />
                    </div>
                  </div>
                  <span>Warehouse</span>
                  <h3 class="card-title text-nowrap mb-1">{{ fettchDataArray.no_of_card_1 }}</h3>
                  <!-- <small class="text-success fw-semibold"><i class="bx bx-up-arrow-alt"></i> +28.42%</small> -->
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-6 mb-4 d-none">
            <div class="card ">
              <div class="card-body">
                <div class="d-flex justify-content-center align-items-center" v-if="loading">
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                <div v-else>
                  <div
                    class="card-title d-flex align-items-start justify-content-between" 
                  >
                    <div class="avatar flex-shrink-0">
                      <img
                        src="/assets/img/icons/unicons/wallet-info.png"
                        alt="Credit Card"
                        class="rounded"
                      />
                    </div>
                  </div>
                  <span>Dispatch</span>
                  <h3 class="card-title text-nowrap mb-1">{{ fettchDataArray.no_of_card_2 }}</h3>
                  <!-- <small class="text-success fw-semibold"><i class="bx bx-up-arrow-alt"></i> +28.42%</small> -->
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-6 mb-4">
            <div class="card ">
              <div class="card-body">
                <div class="d-flex justify-content-center align-items-center" v-if="loading">
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                <div v-else>
                  <div
                    class="card-title d-flex align-items-start justify-content-between" 
                  >
                    <div class="avatar flex-shrink-0">
                      <img
                        src="/assets/img/icons/unicons/wallet-info.png"
                        alt="Credit Card"
                        class="rounded"
                      />
                    </div>
                  </div>
                  <span>In Store</span>
                  <h3 class="card-title text-nowrap mb-1">{{ fettchDataArray.no_of_card_3 }}</h3>
                  <!-- <small class="text-success fw-semibold"><i class="bx bx-up-arrow-alt"></i> +28.42%</small> -->
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-6 mb-4">
            <div class="card ">
              <div class="card-body">
                <div class="d-flex justify-content-center align-items-center" v-if="loading">
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                <div v-else>
                  <div
                    class="card-title d-flex align-items-start justify-content-between" 
                  >
                    <div class="avatar flex-shrink-0">
                      <img
                        src="/assets/img/icons/unicons/wallet-info.png"
                        alt="Credit Card"
                        class="rounded"
                      />
                    </div>
                  </div>
                  <span>Activated</span>
                  <h3 class="card-title text-nowrap mb-1">{{ fettchDataArray.no_of_card_4 }}</h3>
                  <!-- <small class="text-success fw-semibold"><i class="bx bx-up-arrow-alt"></i> +28.42%</small> -->
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-6 mb-4">
            <div class="card ">
              <div class="card-body">
                <div class="d-flex justify-content-center align-items-center" v-if="loading">
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                <div v-else>
                  <div
                    class="card-title d-flex align-items-start justify-content-between" 
                  >
                    <div class="avatar flex-shrink-0">
                      <img
                        src="/assets/img/icons/unicons/wallet-info.png"
                        alt="Credit Card"
                        class="rounded"
                      />
                    </div>
                  </div>
                  <span>Claim</span>
                  <h3 class="card-title text-nowrap mb-1">{{ fettchDataArray.no_of_card_5 }}</h3>
                  <!-- <small class="text-success fw-semibold"><i class="bx bx-up-arrow-alt"></i> +28.42%</small> -->
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-6 mb-4">
            <div class="card ">
              <div class="card-body">
                <div class="d-flex justify-content-center align-items-center" v-if="loading">
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                <div v-else>
                  <div
                    class="card-title d-flex align-items-start justify-content-between" 
                  >
                    <div class="avatar flex-shrink-0">
                      <img
                        src="/assets/img/icons/unicons/wallet-info.png"
                        alt="Credit Card"
                        class="rounded"
                      />
                    </div>
                  </div>
                  <span>Claim Confirm</span>
                  <h3 class="card-title text-nowrap mb-1">{{ fettchDataArray.no_of_card_6 }}</h3>
                  <!-- <small class="text-success fw-semibold"><i class="bx bx-up-arrow-alt"></i> +28.42%</small> -->
                </div>
              </div>
            </div>
          </div>
          <!-- number status -->
          <div class="col-md-12">
            <div class="row">
              <div class="col-6 col-lg-4 col-md-4">
                <div class="card mb-4">
                  <div class="card-body">
                    <div class="d-flex justify-content-center align-items-center" v-if="loading">
                      <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                    <div v-else>
                      <div class="card-title d-flex align-items-start justify-content-between">
                        <div class="avatar flex-shrink-0">
                          <img
                            src="/assets/img/icons/unicons/chart-success.png"
                            alt="chart success"
                            class="rounded"
                          />
                        </div>
                      </div>
                      <span class="fw-semibold d-block mb-1">Number of Store</span>
                      <h3 class="card-title mb-2">{{ fettchDataArray.no_of_store }}</h3>
                      <!-- <small class="text-success fw-semibold"
                        ><i class="bx bx-up-arrow-alt"></i> +72.80%</small
                      > -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-4 col-md-4 ">
                <div class="card mb-4">
                  <div class="card-body">
                    <div class="d-flex justify-content-center align-items-center" v-if="loading">
                      <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                    <div v-else>
                      <div class="card-title d-flex align-items-start justify-content-between">
                        <div class="avatar flex-shrink-0">
                          <img
                            src="/assets/img/icons/unicons/chart-success.png"
                            alt="chart success"
                            class="rounded"
                          />
                        </div>
                      </div>
                      <span class="fw-semibold d-block mb-1">Number of Product</span>
                      <h3 class="card-title mb-2">{{ fettchDataArray.no_of_product }}</h3>
                      <!-- <small class="text-success fw-semibold"
                        ><i class="bx bx-up-arrow-alt"></i> +72.80%</small
                      > -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-4 col-md-4 mb-4">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-center align-items-center" v-if="loading">
                      <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                    <div v-else>
                      <div class="card-title d-flex align-items-start justify-content-between">
                        <div class="avatar flex-shrink-0">
                          <img
                            src="/assets/img/icons/unicons/cc-warning.png"
                            alt="Credit Card"
                            class="rounded"
                          />
                        </div>
                      </div>
                      <span class="fw-semibold d-block mb-1">Alert Product</span>
                      <h3 class="card-title mb-2">{{ fettchDataArray.no_of_alert_product }}</h3>
                      <!-- <small class="text-success fw-semibold"
                        ><i class="bx bx-up-arrow-alt"></i> +28.14%</small
                      > -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-3 mb-4">
                <div class="card ">
                  <div class="card-body">
                    <div class="d-flex justify-content-center align-items-center" v-if="loading">
                      <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                    <div v-else>
                      <div class="card-title d-flex align-items-start justify-content-between" >
                      <div class="avatar flex-shrink-0">
                        <img src="/assets/img/icons/unicons/cube-secondary.png" alt="cube" class="rounded">
                      </div>
                    </div>
                    <span class="fw-medium d-block mb-1">Sales</span>
                    <h3 class="card-title mb-2"> {{ fettchDataArray.no_of_sales }} ({{ fettchDataArray.amount_of_sales }}) </h3>
                    <!-- <small class="text-danger fw-medium"><i class="bx bx-down-arrow-alt"></i> -13.24%</small> -->
                    </div>
                    
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-3 mb-4">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-center align-items-center" v-if="loading">
                      <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                    <div v-else>
                      <div class="card-title d-flex align-items-start justify-content-between" >
                        <div class="avatar flex-shrink-0">
                          <img
                            src="/assets/img/icons/unicons/cc-primary.png"
                            alt="Credit Card"
                            class="rounded"
                          />
                        </div>
                      </div>
                      <span class="fw-semibold d-block mb-1">Wholesale</span>
                      <h3 class="card-title mb-2"> {{ fettchDataArray.no_of_sales_by_wholesale }} ({{ fettchDataArray.amount_of_sales_by_wholesale }})</h3>
                      <!-- <small class="text-success fw-semibold"
                        ><i class="bx bx-up-arrow-alt"></i> +28.14%</small
                      > -->
                    </div>                    
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-3 mb-4">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-center align-items-center" v-if="loading">
                      <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                    <div v-else>
                      <div class="card-title d-flex align-items-start justify-content-between" >
                        <div class="avatar flex-shrink-0">
                          <img
                            src="/assets/img/icons/unicons/cc-primary.png"
                            alt="Credit Card"
                            class="rounded"
                          />
                        </div>
                      </div>
                      <span class="fw-semibold d-block mb-1">Regular</span>
                      <h3 class="card-title mb-2"> {{ fettchDataArray.no_of_sales_by_regular }} ({{ fettchDataArray.amount_of_sales_by_regular }})</h3>
                      <!-- <small class="text-success fw-semibold"
                        ><i class="bx bx-up-arrow-alt"></i> +28.14%</small
                      > -->
                    </div>                    
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-3 mb-4">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-center align-items-center" v-if="loading">
                      <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                    <div v-else> 
                      <div class="card-title d-flex align-items-start justify-content-between">
                        <div class="avatar flex-shrink-0">
                          <img
                            src="/assets/img/icons/unicons/paypal.png"
                            alt="Credit Card"
                            class="rounded"
                          />
                        </div>
                      </div>
                      <span class="d-block mb-1">Total Due</span>
                      <h3 class="card-title text-nowrap mb-2"> {{ fettchDataArray.no_of_due }} ({{ fettchDataArray.amount_of_due }})</h3>
                      <!-- <small class="text-danger fw-semibold"
                        ><i class="bx bx-down-arrow-alt"></i> -14.82%</small
                      > -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end of number status -->
        </div>
      </div>
      <!-- / Content -->
      <!-- Footer -->
      <Footer />
      <!-- / Footer -->
      <!-- <div class="layout-overlay layout-menu-toggle" @click="toggleClass" ></div> -->
    </div>
    <!-- Content wrapper -->
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import { defineComponent, ref, defineAsyncComponent } from 'vue';
import Topbar from '../TopBarView.vue';
import Footer from '../FooterView.vue';
import { useStore } from 'vuex';
import axios from '../../../node_modules/axios/index';
import { DOAMIN_PATH } from "@/api";
// apex chart
// import ChartView from './ChartView.vue';

export default defineComponent({
  name: 'DashboardVue',
  components: {Topbar, Footer},
  data() {
    const store = useStore();
    const token = store.state.auth.token || localStorage.getItem('token');
    const userData = store.state.auth.userData || localStorage.getItem('userData');
    return {
      token,
      userData: JSON.parse(userData),
      // storeId,
      isActiveMenu: false,
      loading: false,
      fettchDataArray: {
        no_of_store: 0,
        no_of_product: 0,
        no_of_alert_product: 0,
        no_of_sales: 0,
        amount_of_sales: 0,
        no_of_sales_by_wholesale: 0,
        amount_of_sales_by_wholesale: 0,
        no_of_sales_by_regular: 0,
        amount_of_sales_by_regular: 0,
        no_of_due: 0,
        amount_of_due: 0,
        no_of_card: 0,
        no_of_card_0: 0,
        no_of_card_1: 0,
        no_of_card_2: 0,
        no_of_card_3: 0,
        no_of_card_4: 0,
        no_of_card_5: 0,
        no_of_card_6: 0,
        no_of_card_7: 0,
        no_of_card_4_today: 0,
      },
    };
  },
  methods: {
    // Method to toggle between login and registration forms
    async fatchDashboardData() {
      try { 
        this.loading = true;
        if (!this.token) {
            throw new Error('Token not found');
          }
          const response = await axios.get(DOAMIN_PATH + 'report-dashboard',
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            }
          }
          );
        if (response.status === 200) {
          this.fettchDataArray = response.data.data;
          this.loading = false;
        }
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    toggleClass() {
      this.isActiveMenu = !this.isActiveMenu;
      const html = document.querySelector("html");
      if (html) {
        html.classList.toggle("layout-menu-expanded", this.isActiveMenu);
      }
    },
  },
  mounted() {
    this.fatchDashboardData();
    // apex chart
  }
});
</script>
<style scoped>
.dashboard {
  text-align: center;
}
.apexcharts-canvas + .apexcharts-toolbar {
  display: none !important;
}
</style>

