<template >
  <div class="d-flex align-items-center" style="min-height: 100vh; width: 100%">
    <!-- <TopBarView /> -->
    <div class="content-wrapper">
      <!-- <Search /> -->
      <!-- Content -->
      <div class="w-100">
        <div>
          <div class="flex-grow-1 container-p-y px-4">
            <div class="col-md-9 col-sm-12 mx-auto">
              <div class="row h-100">
                <div class="col-md-6 mx-auto">
                  <div class="h-100">
                    <div class="d-flex align-items-center justify-content-center h-100">
                      <div class="w-100">
                        <div class="authentication-wrapper authentication-basic container-p-y">
                          <div class="authentication-inner w-100">
                            <!-- login -->
                            <div class="card">
                              <div class="card-body">
                                <!-- Logo -->
                                <div class="app-brand justify-content-center">
                                  <a href="/" class="app-brand-link gap-2">
                                    <span class="app-brand-text demo text-body fw-bold text-uppercase mb-3"> 
                                      <img src="https://pos-file.phicsart.com/profile_user.png" alt="n/a" class="img-fluid" style="width:80px; height:80px;">
                                    </span>
                                  </a>
                                </div>
                                <!-- /Logo -->
                                <h4 class="mb-4 text-center">Raida Trade International &mdash; Status 👋</h4>
                                <div v-if="errorMessages.message" class="alert alert-danger alert-dismissible" role="alert">
                                  {{ errorMessages.message }}
                                  <button type="button" class="btn-close" aria-label="Close"></button>
                                </div>
                                <div class="text-center pb-3">
                                  <div class="row">
                                    <!-- font awsome checked icon -->
                                     <i class="fa fa-check" aria-hidden="true" style="font-size: 100px; color: green;"></i>
                                  </div>
                                  <div class="mt-3">
                                     <h3>Warranty Activated !</h3>
                                     <div class="alert alert-success text-black">
                                      <p class="mb-0"><strong>Product Info: </strong> {{barcodeData.product.name}}</p> 
                                      <p class="mb-0"><strong>Warranty Activated Date: </strong> {{barcodeData.activation_date}}</p> 
                                      <p class="mb-0"><strong>Warranty Expire Date: </strong> {{barcodeData.expire_date}}</p> 
                                     </div>
                                  </div>
                                  <div>
                                    <button type="button" class="btn btn-primary w-100 mt-3" @click="backtoHome"> Back</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>  
          </div>
        </div>
      </div>
      <!-- <Footer /> -->
      <!-- / Footer -->

      <div class="content-backdrop fade"></div>
    </div>
    <!-- / Layout wrapper -->
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
// import Footer from "../FooterView.vue";
// import TopBarView from "../TopBarView.vue";
// import { useStore } from "vuex";
import axios from '../../../../node_modules/axios/index';
import { DOAMIN_PATH } from "@/api";

export default defineComponent({
  // components: { Footer, TopBarView},
  name: "status-view",
  data() {
    // const store = useStore();
    // let userData = store.state.auth.userData || localStorage.getItem('userData');
    // userData = JSON.parse(userData);
    return {
      errorMessages: ref({ message: '' }),
      submitBtn: ref(false),
      barcodeData: {
        id: Number,
        barcode: '',
        remarks: '',
        status: Number,
        activation_date: '',
        expire_date: '',
        order_id: 0,
        product_id: Number,
        stock_id: Number,
        store_id: 0,
        customer_id: Number,
        user_id: Number,
        created_at: '',
        updated_at: '',
        product: {
          id: Number,
          name: '',
          description: '',
          thumb: '',
          buy_price: Number,
          wholesale_price: Number,
          sell_price: Number,
          unit: '',
          alert_quantity: Number,
          features: '',
          user_id: 0,
          created_at: '',
          updated_at: '',
        },
        user: {
          id: Number,
          email: '',
          email_verified_at: '',
          phone: '',
          firstname: '',
          lastname: '',
          profile_img: '',
          national_id_no: '',
          address: '',
          type: '',
          created_at: '',
          updated_at: '',
        },
        customer: {
          id: Number,
          email: '',
          email_verified_at: '',
          phone: '',
          firstname: '',
          lastname: '',
          profile_img: '',
          national_id_no: '',
          address: '',
          type: '',
          created_at: '',
          updated_at: '',
        },
        store: {},
      },
    };
  },
  watch: {

  },
  methods: {
    async backtoHome(){
      this.$emit('update-status');
    },
  },

  mounted() {
    // Set isLoading to true when the component is mounted
    const storedBarcodeData = localStorage.getItem('barcodeData');
    if (storedBarcodeData) {
      try {
        this.barcodeData = JSON.parse(storedBarcodeData);
      } catch (error) {
        console.error('Failed to parse stored barcode data:', error);
      }
    }
    console.log('localstorage Data',this.barcodeData);
  }
});
</script>